import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';

window.platformData = [];

const sentryBeforeSend = (event: Sentry.ErrorEvent) => {
  const ignoredUrls = [
    'event-receiver.dative.es',
    'event-receiver-jx-staging.dative.cloud',
  ];
  if (
    ignoredUrls.some((url) =>
      event?.breadcrumbs?.some((breadcrumb) =>
        breadcrumb?.data?.url?.includes(url)
      )
    )
  ) {
    return null;
  }

  return event;
};

Sentry.init({
  dsn: 'https://c8ec9403a85dc406d5dbcabd948b4096@o4507962379337728.ingest.de.sentry.io/4507962429997136',
  beforeSend: sentryBeforeSend,
  debug: false,
  environment: window._env_.VITE_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  release: 'travel-ui@' + window._env_.VERSION,
});

if (!['localhost', '127.0.0.1'].includes(window.location.hostname)) {
  mixpanel.init(window._env_.VITE_MIXPANEL_TOKEN, {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
