import { useStore } from '@/store/useStore';
import { Box, LinearProgress } from '@mui/material';
import { FC } from 'react';
import * as Styled from './LoadingPage.styled';
import { getLogo } from './utils/getLogo';

export const LoadingPage: FC = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { logoSecondaryUrl } = brandConfig.theme;

  return (
    <Styled.MainContainer>
      <Styled.Container>
        <Styled.ContentContainer>
          {getLogo(logoSecondaryUrl)}
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </Styled.ContentContainer>
      </Styled.Container>
    </Styled.MainContainer>
  );
};
