import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const Calendar = styled.div`
  position: absolute;
  overflow: visible;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 24px 32px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 0px 10px;
  margin-top: 4px;
  z-index: 1;
  padding: 16px;

  @media (min-width: 768px) {
    width: 712px;
    right: -64px;
  }

  @media (max-width: 768px) {
    left: 0px;
  }
`;

export const Input = styled(TextField)`
  label {
    font-size: 18px;
  }

  input {
    text-overflow: ellipsis;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    background-color: unset;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const NightsCounter = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(60, 60, 60);
  padding-top: 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

export const CalendarContainer = styled.div`
  position: absolute;
  overflow: visible;
  background: rgb(255, 255, 255);
  border-radius: 8px;

  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 24px 32px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 0px 10px;
  margin-top: 4px;
  z-index: 1;
  padding: 32px 32px 16px 32px;

  @media (min-width: 768px) {
    width: 712px;
    right: -64px;
  }
  @media (max-width: 768px) {
    left: 0px;
  }
`;
export const Divider = styled.div`
  background-color: rgb(186, 186, 192);
  height: 32px;
  min-width: 1px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileCalendarContainer = styled.div`
  padding-top: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const DateSelectorContainer = styled.div`
  & .rdp-months {
    justify-content: center;
  }
`;

export const MobileDatesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  border-bottom: 1px solid #eaeaeb;
`;

export const Item = styled.div<{ $selected?: boolean; $color: string }>`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 0px 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  transition:
    color 0.2s ease 0s,
    border-bottom 0.2s ease 0s;

  ${({ $selected, $color }) =>
    $selected &&
    css`
      border-bottom: 2px solid ${$color};
      color: ${$color};
      font-weight: 700;
    `}
`;

export const DatesDivider = styled.span`
  padding-bottom: 8px;
`;
