import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Deals.styled';
import { FlashSaleOffersBackground } from './FlashSaleOffersBackground';
import { OffersCarrousel } from '@/views/Deals/components/OffersCarrousel/OffersCarrousel';

export const Offers: FC = () => {
  const [brandConfig, deals] = useStore((state) => [
    state.brandConfig,
    state.deals,
  ]);
  const { t } = useTranslate();
  const offers = brandConfig.offers;
  const primaryColor = brandConfig.theme.primaryColor;

  const dealList = deals.filter((e) => e.feedType === 'Deal card');

  if (isEmpty(dealList)) {
    return null;
  }

  return (
    <Styled.FlashSaleOffersContainer>
      <FlashSaleOffersBackground primaryColor={primaryColor} lined={false} />
      <Styled.InnerContainer>
        <Styled.TitleContainer>
          <div>
            <Styled.SectionTitle>{t(offers.dealsTitleKey)}</Styled.SectionTitle>
            <Styled.Subtitle>{t(offers.dealsSubtitleKey)}</Styled.Subtitle>
          </div>

          {brandConfig.country === 'GB' && <Styled.AtolImage />}
        </Styled.TitleContainer>
        <OffersCarrousel
          dealList={dealList}
          quantityToShow={3}
          showLoadMoreButton
          mobileGrid="carrousel"
        />
      </Styled.InnerContainer>
    </Styled.FlashSaleOffersContainer>
  );
};
