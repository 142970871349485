import * as React from 'react';
import type { InternalFlagProviderProps } from './InternalFlagProvider.types';

import { createContext, useContext, useEffect, useState } from 'react';
import { FeatureToogleService } from '@/services/codegen-welfare';
import { LoadingPage } from '@/components/LoadingPage/LoadingPage';

const InternalFlagContext = createContext(null);

const InternalFlagProvider = ({ children }: InternalFlagProviderProps) => {
  const [flags, setFlags] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =
          await FeatureToogleService.postApiServicesAppFeatureToogleFlagProviderList();
        setFlags(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <InternalFlagContext.Provider value={{ flags, loading, error }}>
      {children}
    </InternalFlagContext.Provider>
  );
};
const useInternalFlag = (flagKey: string) => {
  const context = useContext(InternalFlagContext);
  const flagValue =
    context.flags.toggles.find((t) => t.name === flagKey)?.enabled || false;
  return flagValue;
};

export { InternalFlagProvider, useInternalFlag };
