import { ClearXIcon } from '@/components/LmnIcons/LmnIcons';
import { Modal } from '@/components/Modal';
import { useIsMobile } from '@/hooks/useIsMobile';
import { ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ResultElement, ResultElementProps } from './ResultElement';
import * as Styled from './SearchInput.styled';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';

interface SearchInputProps {
  label: string;
  placeholder?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  selected: ResultElementProps;
  setSelected: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  results: ResultElementProps[];
  onSelect: (e: ResultElementProps) => void;
  onClear: () => void;
  onClickInput: () => void;
  error?: string;
  dataTestKey: string;
}

const SearchInput: FC<SearchInputProps> = ({
  label,
  placeholder,
  value,
  setValue,
  results,
  selected,
  onSelect,
  onClear,
  onClickInput,
  error,
  dataTestKey,
}) => {
  const [openResults, setOpenResults] = useState(false);
  const { isMobile } = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [preselectedResult, setPreselectedResult] = useState<number>(0);

  const { isActiveMemSearch } = useFreeFlightCampaign();

  const handleSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: ResultElementProps
  ) => {
    e.preventDefault();
    onSelect(value);
    setOpenResults(false);
  };

  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setOpenResults(false);
    onClear();
  };

  const handleOpenModal = () => {
    onClickInput();
    if (isMobile) {
      setIsModalOpen(true);
    }
    setPreselectedResult(0);
  };

  const updatePreselectedResult = (keyDownName: string) => {
    if (results) {
      if (keyDownName === 'ArrowUp') {
        let newIndex =
          preselectedResult < 1 ? results.length - 1 : preselectedResult - 1;

        while (
          results[newIndex].disabled ||
          results[newIndex].id === selected?.id
        ) {
          newIndex = newIndex < 1 ? results.length - 1 : newIndex - 1;
        }

        setPreselectedResult(newIndex);
      } else if (keyDownName === 'ArrowDown') {
        let newIndex =
          preselectedResult >= results.length - 1 ? 0 : preselectedResult + 1;

        while (
          results[newIndex].disabled ||
          results[newIndex].id === selected?.id
        ) {
          newIndex = newIndex >= results.length - 1 ? 0 : newIndex + 1;
        }

        setPreselectedResult(newIndex);
      } else if (keyDownName === 'Enter') {
        if (openResults) {
          if (results[preselectedResult]) {
            onSelect(results[preselectedResult]);
            setOpenResults(false);
            isMobile && setIsModalOpen(false);
          }
        } else {
          setPreselectedResult(0);
          setOpenResults(true);
        }
      } else if (keyDownName === 'Tab') {
        onSelect(results[preselectedResult]);
        setOpenResults(false);
        setPreselectedResult(0);
      }
    }
  };

  useEffect(() => {
    if (results?.length > 0 && !!value) {
      setOpenResults(true);
    }
  }, [results]);

  return (
    <Styled.Container data-test={dataTestKey}>
      <TextField
        onClickCapture={handleOpenModal}
        label={label}
        placeholder={placeholder}
        value={selected?.title || value}
        onChange={(e) => setValue(e.target.value)}
        onClick={() => setOpenResults(true)}
        onKeyDown={(e) => {
          if (
            e.code === 'ArrowUp' ||
            e.code === 'ArrowDown' ||
            e.code === 'Enter' ||
            e.code === 'Tab'
          ) {
            updatePreselectedResult(e.code);
          } else {
            selected && onClear();
          }
        }}
        fullWidth
        autoComplete="off"
        slotProps={{
          inputLabel: { shrink: true },
          input: {
            endAdornment: (value || selected) && (
              <InputAdornment position="end" onClick={(e) => handleClear(e)}>
                <ClearXIcon size={20} style={{ color: 'rgb(186, 186, 192)' }} />
              </InputAdornment>
            ),
          },
        }}
        error={!!error}
        sx={{
          '& label': {
            fontSize: '18px',
          },
          '& input': {
            textOverflow: 'ellipsis',
          },
        }}
      />
      {error && <Styled.Error>{error}</Styled.Error>}
      {results?.length > 0 && openResults && !isMobile && (
        <ClickAwayListener
          children={
            <Styled.ResultsContainer>
              <Styled.ListContainer>
                {selected && (
                  <ResultElement {...selected} selected isSubElement={false} />
                )}
                {results.map((element, index) => (
                  <div
                    onClick={(event) => {
                      if (!element.disabled) {
                        handleSelect(event, element);
                      }
                    }}
                    key={index}
                    style={{
                      display: `${element.code === selected?.code ? 'none' : 'block'}`,
                    }}
                    data-test="location-result-item"
                  >
                    <ResultElement
                      {...element}
                      //NOTE: check disabled to meemSearch
                      disabled={
                        (element.type === 'Country' && !isActiveMemSearch) ||
                        element.disabled
                      } // TODO: Remove this when MS available
                      preSelected={index === preselectedResult}
                    />
                  </div>
                ))}
              </Styled.ListContainer>
            </Styled.ResultsContainer>
          }
          onClickAway={() => setOpenResults(false)}
        ></ClickAwayListener>
      )}
      <Modal
        title={label}
        isModalOpen={isModalOpen}
        fullHeight
        onCloseModal={() => setIsModalOpen(false)}
        closable
      >
        <Styled.TextField
          label={label}
          placeholder={placeholder}
          value={selected?.title || value}
          onChange={(e) => setValue(e.target.value)}
          onClick={() => setOpenResults(true)}
          onKeyDown={(e) => {
            if (
              e.code === 'ArrowUp' ||
              e.code === 'ArrowDown' ||
              e.code === 'Enter' ||
              e.code === 'Tab'
            ) {
              updatePreselectedResult(e.code);
            } else {
              selected && onClear();
            }
          }}
          fullWidth
          autoComplete="off"
          inputRef={(input) => input && input.focus()}
          error={!!error}
          sx={{
            '& label': {
              fontSize: '18px',
            },
            '& input': {
              textOverflow: 'ellipsis',
            },
          }}
          slotProps={{
            inputLabel: { shrink: true },
            input: {
              endAdornment: value && (
                <InputAdornment position="end" onClick={(e) => handleClear(e)}>
                  <ClearXIcon
                    size={20}
                    style={{ color: 'rgb(186, 186, 192)' }}
                  />
                </InputAdornment>
              ),
            },
          }}
        />
        {results?.length > 0 && openResults && (
          <Styled.MobileListContainer>
            {selected && (
              <ResultElement {...selected} selected isSubElement={false} />
            )}
            {results.map((element, index) => (
              <div
                onClick={(event) => {
                  if (!element.disabled) {
                    handleSelect(event, element);
                  }
                  setIsModalOpen(false);
                }}
                key={index}
                style={{
                  display: `${element.code === selected?.code ? 'none' : 'block'}`,
                }}
              >
                <ResultElement
                  {...element}
                  preSelected={index === preselectedResult}
                />
              </div>
            ))}
          </Styled.MobileListContainer>
        )}
      </Modal>
    </Styled.Container>
  );
};

export { SearchInput };
