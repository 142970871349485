import * as React from 'react';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { DateSelectorProps } from './DateSelector.types.ts';
import { ProductType } from '../../Search.types.ts';

type DateSelectorEventProps = {
  dateRange: DateSelectorProps['dateRange'];
  onSelectedDateRange: DateSelectorProps['onSelectedDateRange'];
  onCloseDatePicker?: () => void;
  errors?: Record<string, unknown> | null;
  onError?: (e: Record<string, unknown> | null) => void;
  productType?: ProductType;
};

export function useDateSelectorEvents({
  dateRange,
  onSelectedDateRange,
  onCloseDatePicker,
  errors,
  onError,
  productType,
}: DateSelectorEventProps) {
  const dateRef = React.useRef(null);

  const [fromSelected, setFromSelected] = React.useState<boolean>(false);

  const onSelect = (date: DateRange | Date) => {
    if (date instanceof Date) {
      if (dayjs(date).isSame(dateRange.from)) {
        return;
      }
      console.log('hola');

      onSelectedDateRange({
        from: dayjs(date),
        //NOTE: it is necessary to have a data to display in the other date selectors
        to: dayjs(date).add(1, 'd'),
      });

      delete errors?.['dateRange'];

      onError?.(errors);

      return;
    }

    if (productType === 'F') {
      if (!fromSelected) {
        dateRef.current = date.from;

        if (dayjs(date.from).isBefore(dateRange.from)) {
          onSelectedDateRange({
            from: date ? dayjs(date.from) : dateRange.from,
            to: date ? dayjs(date.from) : dateRange.from,
          });
        } else {
          onSelectedDateRange({
            from: date ? dayjs(date.to) : dateRange.from,
            to: date ? dayjs(date.to) : dateRange.from,
          });
        }

        setFromSelected(true);
        return;
      } else {
        dateRef.current = date ? date.from : dateRange.to;

        onSelectedDateRange({
          from: date ? dayjs(date.from) : dateRange.from,
          to: date ? dayjs(date.to) : dateRange.from,
        });

        setFromSelected(false);
        onCloseDatePicker?.();

        return;
      }
    }

    const isClickToDateFrom =
      dayjs(date.from).isSame(dateRange.from) &&
      dayjs(date.to).isSame(dateRange.from);

    const isClickToDateTo =
      dayjs(date.to).isSame(dateRange.to) &&
      dayjs(date.from).isSame(dateRange.to);

    if (isClickToDateFrom) {
      dateRef.current = date.from;

      return;
    }

    if (dayjs(date.from).isBefore(dateRange.from)) {
      onSelectedDateRange({
        from: dayjs(date.from),
        to: dayjs(dateRange.to),
      });

      delete errors?.['dateRange'];

      onError?.(errors);

      dateRef.current = date.from;
    } else {
      if (dayjs(date.to).isBefore(dateRange.to)) {
        if (dateRef.current) {
          onSelectedDateRange({
            from: dayjs(dateRef.current),
            to: dayjs(date.to),
          });

          delete errors?.['dateRange'];

          onError?.(errors);

          dateRef.current = null;

          onCloseDatePicker?.();
        } else {
          onSelectedDateRange({
            from: dayjs(date.to),
            to: dayjs(dateRange.to),
          });

          delete errors?.['dateRange'];

          onError?.(errors);

          dateRef.current = date.to;
        }

        return;
      }

      if (dayjs(date.to).isAfter(dateRange.to) || isClickToDateTo) {
        if (dateRef.current) {
          onSelectedDateRange({
            from: dayjs(dateRef.current),
            to: dayjs(date.to),
          });

          delete errors?.['dateRange'];

          onError?.(errors);

          dateRef.current = null;

          onCloseDatePicker?.();
        } else {
          onSelectedDateRange({
            from: dayjs(date.to),
            to: dayjs(date.to).add(1, 'd'),
          });

          delete errors?.['dateRange'];

          onError?.(errors);

          dateRef.current = date.to;
        }
        return;
      }
    }
  };

  const onRemoveDateRefCurrent = () => (dateRef.current = null);
  return {
    onSelect,
    onRemoveDateRefCurrent,
  };
}
