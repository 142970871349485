import { DetailsObject } from './types/DetailsObject';
import { LandingObject } from './types/LandingObject';
import { ResultsObject } from './types/ResultsObject';

export const usePushPlatformData = () => {
  const pushData = <T>(object: T) => {
    if (window.platformData) {
      window.platformData.push(object);
    } else {
      console.error('platformData is not defined');
    }
  };

  return {
    pushLanding: (object: LandingObject) => pushData(object),
    pushResults: (object: ResultsObject) => pushData(object),
    pushDetails: (object: DetailsObject) => pushData(object),
  };
};
