/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplyVoucherInputDto } from '../models/ApplyVoucherInputDto';
import type { CartDetailResponseDto } from '../models/CartDetailResponseDto';
import type { ConfigResponseDto } from '../models/ConfigResponseDto';
import type { ContactInputDto } from '../models/ContactInputDto';
import type { CountryInList } from '../models/CountryInList';
import type { CreateCartResponseDto } from '../models/CreateCartResponseDto';
import type { InvoiceDto } from '../models/InvoiceDto';
import type { NewCartInputDto } from '../models/NewCartInputDto';
import type { ProductInputDto } from '../models/ProductInputDto';
import type { SpecialRequestInputDto } from '../models/SpecialRequestInputDto';
import type { Subscription } from '../models/Subscription';
import type { SubscriptionInputDto } from '../models/SubscriptionInputDto';
import type { Traveller } from '../models/Traveller';
import type { VoucherDto } from '../models/VoucherDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CartApiServiceService {

    /**
     * @returns CreateCartResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceCreateCart({
        requestBody,
    }: {
        requestBody?: NewCartInputDto,
    }): CancelablePromise<CreateCartResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/CreateCart',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ConfigResponseDto Success
     * @throws ApiError
     */
    public static getApiServicesAppCartApiServiceGetCartConfig({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<ConfigResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/CartApiService/GetCartConfig',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns CartDetailResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceRetrieveCart({
        cartId,
        requestUuid,
        domainName,
        producttype,
    }: {
        cartId?: string,
        requestUuid?: string,
        domainName?: string,
        producttype?: string,
    }): CancelablePromise<CartDetailResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/RetrieveCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
                'domainName': domainName,
                'producttype': producttype,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceApplyVoucherToCart({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: ApplyVoucherInputDto,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/ApplyVoucherToCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns VoucherDto Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceRetrieveVoucherCode({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<VoucherDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/RetrieveVoucherCode',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiServicesAppCartApiServiceRemoveVoucherFromCart({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/services/app/CartApiService/RemoveVoucherFromCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceAddContactToCart({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: ContactInputDto,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/AddContactToCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ContactInputDto Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceRetrieveContact({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<ContactInputDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/RetrieveContact',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceAddTravellerToCart({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: Array<Traveller>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/AddTravellerToCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns Traveller Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceRetrieveTravellersByCartId({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<Array<Traveller>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/RetrieveTravellersByCartId',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns CountryInList Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceRetrieveCountries({
        lang,
    }: {
        lang?: string,
    }): CancelablePromise<Array<CountryInList>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/RetrieveCountries',
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceAddSubscriptions({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: Array<SubscriptionInputDto>,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/AddSubscriptions',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns Subscription Success
     * @throws ApiError
     */
    public static getApiServicesAppCartApiServiceGetSubscriptions({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<Array<Subscription>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/CartApiService/GetSubscriptions',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceCartFinalize({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/CartFinalize',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceAddInvoice({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: InvoiceDto,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/AddInvoice',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns InvoiceDto Success
     * @throws ApiError
     */
    public static getApiServicesAppCartApiServiceGetInvoices({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<Array<InvoiceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/CartApiService/GetInvoices',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiServicesAppCartApiServiceGetOrders({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/CartApiService/GetOrders',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getApiServicesAppCartApiServiceGetAvailableProducts({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/CartApiService/GetAvailableProducts',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceAddProductToCart({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: Array<ProductInputDto>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/AddProductToCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceReplaceProductsInCart({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: Array<ProductInputDto>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/ReplaceProductsInCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiServicesAppCartApiServiceRemoveProductsFromCart({
        cartId,
        productIds,
        requestUuid,
    }: {
        cartId?: string,
        productIds?: Array<string>,
        requestUuid?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/services/app/CartApiService/RemoveProductsFromCart',
            query: {
                'CartId': cartId,
                'ProductIds': productIds,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceAddSpecialRequestToCart({
        cartId,
        requestUuid,
        requestBody,
    }: {
        cartId?: string,
        requestUuid?: string,
        requestBody?: SpecialRequestInputDto,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/AddSpecialRequestToCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns SpecialRequestInputDto Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceRetrieveSpecialRequestByCartId({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<SpecialRequestInputDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/RetrieveSpecialRequestByCartId',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiServicesAppCartApiServiceDeleteSpecialRequestByCartId({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/services/app/CartApiService/DeleteSpecialRequestByCartId',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiServicesAppCartApiServiceConfirmCart({
        cartId,
        requestUuid,
    }: {
        cartId?: string,
        requestUuid?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/CartApiService/ConfirmCart',
            query: {
                'cartId': cartId,
                'requestUuid': requestUuid,
            },
        });
    }

}
