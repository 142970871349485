import * as React from 'react';
import { TrackerContextProps } from './TrackerProvider.types';

export const TrackerProviderContext =
  React.createContext<TrackerContextProps>(null);

export const useTracker = () => {
  const contextValue = React.useContext<TrackerContextProps>(
    TrackerProviderContext
  );

  if (contextValue === null) {
    throw new Error('[useTracker]: context must be initialized');
  }

  return contextValue;
};
