import React, { useEffect, useRef } from 'react';
import { createCommonElementInjector } from './commonElementInjector';

type Props = {
  url: string;
  className?: string;
};

export const CommonElement: React.FC<Props> = ({ url, ...otherProps }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    createCommonElementInjector(ref, url).inject();
  }, [url]);

  return <div ref={ref} {...otherProps} />;
};
