import * as React from 'react';
import { useStore } from '@/store/useStore';
import { useGetParam } from '@/hooks/useGetParam';

export function useCommomTrackerData() {
  const brandConfig = useStore((s) => s.brandConfig);

  const getParam = useGetParam();

  const externalAuth = useStore((s) => s.externalAuth);

  const clientData = externalAuth?.clientData;

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH ? 'HGH' : getParam('product');

  const cartId = getParam('cartId');

  const domain = window.location.hostname;

  return React.useMemo(
    () => ({
      appVersion: window._env_.VERSION,
      externalUserId: clientData?.userId,
      brand: brandConfig?.brand?.businessClient,
      subBrand: brandConfig?.brand?.brandName,
      cartId,
      product,
      domain: domain,
    }),
    [
      brandConfig?.brand?.businessClient,
      brandConfig?.brand?.brandName,
      cartId,
      product,
      domain,
      clientData?.userId,
    ]
  );
}
