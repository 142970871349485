import { useInternalFlag } from '@/providers/InternalFlagProvider';
import { getSearchParam } from '../utils/getSearchParam';

export function useGetFlag(key: string) {
  const flagValue = useInternalFlag(key);
  const queryParam = getSearchParam(key);

  if (queryParam) {
    return queryParam === 'true' ? true : false;
  } else {
    return flagValue;
  }
}
