import { getCookieValue } from '@/store/utils/authenticateExternal/getCookieValue.ts';
import { Cookies } from '@/utils/cookies.ts';

const LMN_MEASUREMENT_COOKIE_KEY = '5';

export const getCookiesValuesCommon = () => {
  const cookieAcceptanceValue =
    getCookieValue('cookies-acceptance')?.split(',');

  const hasCookieAcceptance = cookieAcceptanceValue?.includes('measurement');

  const cookieGTMValue = getCookieValue('oil_GTM_cookie');

  const hasLmnMeasurementCookie =
    cookieGTMValue ??
    JSON.parse(cookieGTMValue)?.[LMN_MEASUREMENT_COOKIE_KEY] === true;

  const hasFingerprint = hasCookieAcceptance || hasLmnMeasurementCookie;

  const fingerprint = hasFingerprint
    ? Cookies.getCookieValue('user-fingerprint')
    : null;

  const sessionFingerprint = Cookies.getCookieValue('session-user-fingerprint');

  return {
    fingerprint,
    sessionFingerprint,
  };
};
