import { useInternalFlag } from '@/providers/InternalFlagProvider';
import { useStore } from '@/store/useStore';

interface useFreeFlightCampaignProps {
  isFreeFlight: boolean;
  departureCountry?: string;
  adults?: number;
  voucherAmount?: number;
  isActiveMemSearch: boolean;
}

export function useFreeFlightCampaign(): useFreeFlightCampaignProps {
  const brandConfig = useStore((state) => state.brandConfig);

  const freeFlightConditions = brandConfig?.brand?.freeFlightConditions;

  const isActiveMemSearch = useInternalFlag('travel.search.memsearch');

  if (freeFlightConditions) {
    return {
      isFreeFlight: true,
      isActiveMemSearch,
      ...freeFlightConditions,
    };
  }

  return {
    isFreeFlight: false,
    isActiveMemSearch: false,
  };
}
