import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import { CommonElement } from './CommonElement';
import { FreeFlightFooter } from './FreeFlightFooter';
import * as Styled from './Footer.styled';
import { useTranslate } from '@tolgee/react';

export function Footer() {
  const brandConfig = useStore((state) => state.brandConfig);

  const freeFlightCampaign = useFreeFlightCampaign();

  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const isLocal = window.location.hostname === 'localhost';

  const { t } = useTranslate();

  if (isFreeFlight) {
    return <FreeFlightFooter />;
  }

  const emebedScriptURL = brandConfig.footer.embebed.scriptUrl;

  if (!!emebedScriptURL && !isLocal) {
    return <CommonElement url={emebedScriptURL} />;
  }

  return (
    <Styled.Container>
      <Styled.Text $primaryColor={brandConfig.theme.primaryColor}>
        <div
          dangerouslySetInnerHTML={{
            __html: brandConfig.footer.custom.legalText
              ? t(brandConfig.footer.custom.legalText)
              : t('footer.rewardwave.text'),
          }}
        ></div>{' '}
      </Styled.Text>
      <img
        src={brandConfig.theme.logoSecondaryUrl}
        alt="page logo"
        height="40px"
      />
    </Styled.Container>
  );
}
