import * as Styled from './CalendarSelector.styled';
import { ClickAwayListener } from '@mui/material';
import { Flexibledates } from '@/components/Flexibledates';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import 'react-day-picker/style.css';
import { Modal } from '@/components/Modal';
import { timeStays } from './utils';

function CalendarInput({ text, onClick }) {
  const { t } = useTranslate();

  return (
    <Styled.InnerContainer>
      <Styled.Input
        label={t('search.when')}
        value={text}
        onClick={onClick}
        fullWidth
        autoComplete="off"
        slotProps={{ inputLabel: { shrink: true } }}
      />
    </Styled.InnerContainer>
  );
}

export function CalendarSelector(props) {
  const { isMobile } = useIsMobile();

  const { t } = useTranslate();

  return (
    <Styled.Container>
      <CalendarInput
        text={props.calendarSelectorText}
        onClick={props.onClickCalendarSelectorInput}
      />
      {props.isOpenCalendarSelector && !isMobile && (
        <ClickAwayListener
          children={
            <Styled.Calendar>
              <Flexibledates
                onTimeStay={props.propsFlexiDates.onTimeStay}
                onDate={props.propsFlexiDates.onDate}
                dates={props.propsFlexiDates.dates}
                timeStays={timeStays}
                currentState={props.propsFlexiDates}
              />
            </Styled.Calendar>
          }
          onClickAway={props.onCloseCalendarSelector}
        />
      )}
      {isMobile && (
        <Modal
          title={t('search.dates')}
          isModalOpen={props.isOpenCalendarSelector}
          fullHeight
          onCloseModal={props.onCloseCalendarSelector}
          closable
        >
          <Styled.MobileCalendarContainer>
            <Flexibledates
              onTimeStay={props.propsFlexiDates.onTimeStay}
              onDate={props.propsFlexiDates.onDate}
              dates={props.propsFlexiDates.dates}
              timeStays={timeStays}
              currentState={props.propsFlexiDates}
            />
          </Styled.MobileCalendarContainer>
        </Modal>
      )}
    </Styled.Container>
  );
}
