import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useCurrencyFormatter } from '@/hooks/useCurrencyFormatter';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './RecommendedSportEvents.styled';
import { generateUrl } from '../../../Deals/utils/generateUrl';

const strapiDealsForDecathlon = [
  {
    departure: 'BCN',
    departureName: 'Barcelona',
    arrival: '136281',
    arrivalType: 'City',
    arrivalName: 'Madrid',
    hotelId: '',
    hotelName: '',
    intervalStart: '2025-09-12',
    intervalEnd: '2025-09-14',
    travellersAdults: 2,
    childrenAges: 'none',
    arrivalImage:
      'https://m3padelacademy.com/wp-content/uploads/2024/12/WEEKEND-NUEVO-BANNER-II-2-1.jpg',
    price: 549,
    event: {
      date: '12-14/09/25',
      name: 'M3 Padel Weekend',
      description:
        '¡Coaching de primer nivel en un entorno dinámico y profesional!',
      days: 'Evento de 3 días',
    },
  },
  {
    departure: 'MAD',
    departureName: 'Madrid',
    arrival: '157399',
    arrivalType: 'City',
    arrivalName: 'Doha',
    hotelId: '',
    hotelName: '',
    intervalStart: '2026-01-11',
    intervalEnd: '2026-01-11',
    travellersAdults: 2,
    childrenAges: 'none',
    arrivalImage:
      'https://assets.weebora.com/images/padel_in_venue_pistas_academy_middle_east_weebora_padel_6328b60e07.jpg',
    price: 100,
    event: {
      date: '11/01/26',
      name: '1 hour Clinic - Padel In - Aspire Zona Padel Club - Doha',
      description: '¡Un viaje de pádel inolvidable en un entorno excepcional!',
      days: 'Evento de 1 día',
    },
  },
  {
    departure: 'MAD',
    departureName: 'Madrid',
    arrival: '136323',
    arrivalType: 'City',
    arrivalName: 'Málaga',
    hotelId: '',
    hotelName: '',
    intervalStart: '2026-01-09',
    intervalEnd: '2026-01-11',
    travellersAdults: 2,
    childrenAges: 'none',
    arrivalImage:
      'https://assets.weebora.com/images/weebora_padel_malaga_spain_district_multi_storey_residential_2023_11_27_05_05_39_utc_e05a0012a9.jpg',
    price: 450,
    event: {
      date: '09-11/01/26',
      name: 'Padel Travel Club Trips - 3 days - Málaga',
      description:
        '¡Mejora la participación de tu club con esta increíble experiencia!',
      days: 'Evento de 3 días',
    },
  },
];

export const RecommendedSportEvents: FC = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { primaryColor } = brandConfig.theme;
  const { t } = useTranslate();
  const currencyFormatter = useCurrencyFormatter();

  const sportEventList = strapiDealsForDecathlon;

  if (isEmpty(sportEventList) || brandConfig.brand.brandName !== 'Decathlon') {
    return null;
  }
  return (
    <Styled.RecommendedSportEventsContainer>
      <Styled.InnerContainer>
        <Styled.TitleContainer>
          <div>
            <Styled.SectionTitle>Experiencias de padel</Styled.SectionTitle>
            <Styled.Subtitle>
              ¿Quieres tener todo organizado para tu próximos torneo de pádel?
              Aquí tienes las mejores opciones
            </Styled.Subtitle>
          </div>
        </Styled.TitleContainer>
        <Styled.SportOffersCarousel>
          {sportEventList.map((e, key) => {
            const url = generateUrl(e);
            return (
              <Styled.EventLink href={url} key={key}>
                <Styled.EventContainer>
                  <Styled.EventImage url={e?.arrivalImage} />
                  <Styled.TripDaysTitle color={primaryColor}>
                    <span>{e.event.days}</span>
                    <span>{e.event.date}</span>
                  </Styled.TripDaysTitle>
                  <Styled.LocationTag>{e.arrivalName}</Styled.LocationTag>
                  <Styled.EventContent>
                    <Styled.Name>{e.event.name}</Styled.Name>
                    <Styled.Description>
                      {e.event.description}
                    </Styled.Description>
                    <Styled.Tag color={primaryColor}>
                      {t('general.price.from')}{' '}
                      {currencyFormatter.formatNumber(e.price)}
                    </Styled.Tag>
                  </Styled.EventContent>
                </Styled.EventContainer>
              </Styled.EventLink>
            );
          })}
        </Styled.SportOffersCarousel>
      </Styled.InnerContainer>
    </Styled.RecommendedSportEventsContainer>
  );
};
