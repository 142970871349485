import { useTranslate } from '@tolgee/react';
import { Dayjs } from 'dayjs';
import * as React from 'react';
import { getDates } from './FlexiDatesSelector/utils';

export type FlexiDatesDataProps = {
  dateToLabel?: string;
  dayInit?: string;
  isAnyDate: boolean;
  dateFrom?: string;
  dateTo: string;
  monthInit: Dayjs | null;
  monthEnd: Dayjs | null;
};

export function useFlexiDates(brandConfig) {
  const { t } = useTranslate();
  const [flexiDatesData, setFlexiDatesData] =
    React.useState<FlexiDatesDataProps>({
      dateToLabel: `1 ${t('general.night')}`,
      dayInit: t('general.time.anytime'),
      isAnyDate: true,
      dateFrom: 'A',
      dateTo: 'T,1,1',
      monthInit: null,
      monthEnd: null,
    });

  const dates = getDates(brandConfig.language);

  const onTimeStay = (data) => {
    setFlexiDatesData((s) => ({
      ...s,
      dateTo: `T,${data?.value?.join(',')}`,
      dateToLabel: data.text,
      dateId: data.id,
    }));
  };

  const onDate = (value) => {
    const dayInitFormatted = value?.dayInit?.format('YYYY-MM-DD');

    const dayEndFormatted = value?.dayEnd?.format('YYYY-MM-DD');

    setFlexiDatesData((s) => ({
      ...s,
      ...value,
      dateFrom: value.isAnyDate
        ? 'A'
        : `${dayInitFormatted},${dayEndFormatted}`,
    }));
  };

  const isSameDays =
    !flexiDatesData.isAnyDate &&
    flexiDatesData?.monthInit.isSame(flexiDatesData?.monthEnd);

  const dateTimeLineNotSameDaysLabel = `${flexiDatesData?.monthInit?.format('MMM')}${flexiDatesData?.monthEnd?.format('MMM') ? ` - ${flexiDatesData?.monthEnd?.format('MMM')}` : ''}`;

  const dateTimelineLabel = isSameDays
    ? flexiDatesData?.monthInit?.format('MMM')
    : dateTimeLineNotSameDaysLabel;

  const flexiDatesLabel = `${flexiDatesData?.dateToLabel} • ${flexiDatesData.isAnyDate ? t('general.time.anytime') : dateTimelineLabel}`;

  return {
    flexiDatesLabel,
    onDate,
    onTimeStay,
    flexiDatesData,
    dates,
  };
}
