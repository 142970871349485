import styled from '@emotion/styled';
import { DayPicker } from 'react-day-picker';
import { css } from '@emotion/react';

export const DatePicker = styled(DayPicker)<{
  $color: string;
  $bgColor: string;
  $isOnlyDateRangeFrom?: boolean;
}>`
  .preselected {
    background: ${({ $bgColor }) => $bgColor};
    border-radius: 0 !important;

    button {
      background-color: unset;
      color: ${({ $color }) => $color};
      border: unset;
    }
  }
  .firstPreselected {
    border-radius: 25px 0 0 25px !important;
  }
  .lastPreselected {
    border-radius: 0 25px 25px 0 !important;
  }
  .firstPreselected.lastPreselected {
    border-radius: 25px !important;
  }
  & .rdp-month_caption {
    color: rgb(60, 60, 60);
    display: block;
    height: 40px;
    line-height: 40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: none;
  }

  & .rdp-nav {
    width: 100%;
    justify-content: space-between;
  }

  & .rdp-range_middle {
    color: ${({ $color }) => $color};
    background-color: ${({ $bgColor }) => $bgColor};
  }

  & .rdp-range_start {
    background: ${({ $bgColor }) => $bgColor};
    border-radius: 25px 0 0 25px;
  }

  & .rdp-range_end {
    background: ${({ $bgColor }) => $bgColor};
    border-radius: 0 25px 25px 0;
  }

  & .rdp-selected {
    font: unset;
  }

  & .rdp-range_start button {
    background-color: unset;
    color: ${({ $color }) => $color};
    border: unset;
  }

  & .rdp-range_end button {
    background-color: unset;
    color: ${({ $color }) => $color};
    border: unset;
  }

  & .rdp-week {
    margin-top: 4px;
    border-radius: 4px;
    overflow: hidden;

    .rdp-day:first-child:not(.firstPreselected) {
      border-radius: 5px 0 0 5px !important;
    }
    .rdp-day:last-child:not(.lastPreselected) {
      border-radius: 0 5px 5px 0 !important;
    }
  }

  & .rdp-button_next:hover {
    background-color: rgb(245, 245, 245);
    border-radius: 25px;
  }

  & .rdp-button_previous:hover {
    background-color: rgb(245, 245, 245);
    border-radius: 25px;
  }

  & .rdp-button_next svg {
    fill: rgb(60, 60, 60);
  }

  & .rdp-button_previous svg {
    fill: rgb(60, 60, 60);
  }

  & .rdp-day_button:hover {
    border: 1px solid ${({ $color }) => $color};
    border-radius: 50px;
  }

  & .rdp-day_button {
    width: 40px;
    height: 40px;
  }

  & .rdp-today:not(.rdp-outside) {
    color: inherit;
  }

  ${({ $isOnlyDateRangeFrom, $color, $bgColor }) =>
    $isOnlyDateRangeFrom &&
    css`
      & .rdp-selected .rdp-day_button {
        border: 1px solid ${$color};
        color: ${$color};
        background-color: ${$bgColor};
      }
    `}
`;
