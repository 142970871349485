import styled from '@emotion/styled';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 576px) {
    max-width: 540px !important;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;
