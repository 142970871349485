import { StaticData } from '@/services/codegen-romulo';
import { AccomodationObjectMap } from '../types/MappingTypes';
import dayjs from 'dayjs';

export function getPricingAccommodation<T extends 'details'>({
  staticData,
  numberOfRooms,
  startDate,
  mealPlan,
}: {
  staticData: StaticData;
  numberOfRooms: number;
  duration?: string;
  startDate: string;
  internalId?: string;
  mealPlan?: string;
}): AccomodationObjectMap[T] {
  const accommodation = staticData.accommodation;
  const destinationCity = staticData?.cities?.find(
    (city) => city.id === accommodation?.internalIdCity
  );
  const destinationCountry = staticData?.countries?.find(
    (c) => c.id === destinationCity?.countryId
  );
  const destinationContinent = staticData?.continents?.find(
    (c) => c.id === destinationCountry?.continentId
  );

  const basicAccommodationObject = {
    class: accommodation.stars?.toString(),
    destinationCode: String(accommodation.internalIdCity),
    destinationName: destinationCity?.label,
    destinationCountryName: destinationCountry?.label,
    destinationCountryCode: destinationCity?.countryId,
    destinationContinentCode: destinationContinent?.id,
    destinationContinentName: destinationContinent?.label,
    isTopSecret: staticData.proposal.type === 'TOP_SECRET_HOTEL',
    mealPlan,
    name: accommodation.label,
    providerId: undefined, // TODO We don't have this info
    providerName: undefined, // TODO We don't have this info
    quantity: numberOfRooms,
    rating: accommodation.opinion?.rating,
    reviewsNumber: accommodation.opinion?.numberOfReviews,
    startDate: dayjs(startDate).format('YYYYMMDD'),
    type: accommodation.accommodationType,
    withPhoto: accommodation.images && accommodation.images.length > 0,
    company: accommodation.chain?.id,
  };

  return basicAccommodationObject;
}
