import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import * as Styled from './CabinClass.styled';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useStore } from '@/store/useStore';
import { CabinType } from '@/utils/types/CabinClass';

interface CabinClassProps {
  onCabinClassSelector: (values: Record<string, unknown>) => void;
  cabinClass: CabinType;
}

const CabinClass: FC<CabinClassProps> = ({
  onCabinClassSelector,
  cabinClass,
}) => {
  const [selectedCabinClass, setSelectedCabinClass] =
    useState<CabinType>(cabinClass);

  const { t } = useTranslate();

  const { isMobile } = useIsMobile();

  const brandConfig = useStore((s) => s.brandConfig);

  const { primaryColor, primaryColorBg } = brandConfig.theme;

  const handleSelectedCabinClass = (newKey: CabinType) => {
    setSelectedCabinClass(newKey);
    onCabinClassSelector({ cabinClass: newKey });
  };

  return (
    <Styled.CabinClassContainer $isMobile={isMobile}>
      <Styled.TitleContainer>
        <Styled.Title>{`${t('search.flight.cabin_class')}`}</Styled.Title>
      </Styled.TitleContainer>
      <Styled.TagsContainer>
        <Styled.Chip
          label={t('flight.class.all')}
          $isSelected={selectedCabinClass === 'all'}
          $primaryColor={primaryColor}
          $primaryColorBg={primaryColorBg}
          onClick={() => handleSelectedCabinClass('all')}
        />
        <Styled.Chip
          label={t('flight.class.economy')}
          $isSelected={selectedCabinClass === 'Y'}
          $primaryColor={primaryColor}
          $primaryColorBg={primaryColorBg}
          onClick={() => handleSelectedCabinClass('Y')}
        />
        <Styled.Chip
          label={t('flight.class.premium_economy')}
          $isSelected={selectedCabinClass === 'P'}
          $primaryColor={primaryColor}
          $primaryColorBg={primaryColorBg}
          onClick={() => handleSelectedCabinClass('P')}
        />
        <Styled.Chip
          label={t('flight.class.business')}
          $isSelected={selectedCabinClass === 'C'}
          $primaryColor={primaryColor}
          $primaryColorBg={primaryColorBg}
          onClick={() => handleSelectedCabinClass('C')}
        />
        <Styled.Chip
          label={t('flight.class.first_class')}
          $isSelected={selectedCabinClass === 'F'}
          $primaryColor={primaryColor}
          $primaryColorBg={primaryColorBg}
          onClick={() => handleSelectedCabinClass('F')}
        />
      </Styled.TagsContainer>
    </Styled.CabinClassContainer>
  );
};

export { CabinClass };
