import { FlightTypes } from '@/utils/types/FlightTypes';
import { SearchSchema } from './schema.validate';
import { ProductType } from './Search.types';

export const isValidateSearch = (travelInfo, calendarSelector) => {
  if (travelInfo.productType === ProductType.DP) {
    const event = SearchSchema.safeParse({
      departure: travelInfo.departure,
      destination: travelInfo.destination,
      rooms: travelInfo.rooms,
      dateRange: calendarSelector.propsExactDates.dateRange,
    });

    return {
      success: event.success,
      error: event?.error?.flatten()?.fieldErrors,
    };
  }

  if (travelInfo.productType === ProductType.H) {
    const event = SearchSchema.omit({ departure: true }).safeParse({
      departure: travelInfo.departure,
      destination: travelInfo.destination,
      rooms: travelInfo.rooms,
      dateRange: calendarSelector.propsExactDates.dateRange,
    });

    return {
      success: event.success,
      error: event?.error?.flatten()?.fieldErrors,
    };
  }

  if (travelInfo.productType === ProductType.F) {
    if (travelInfo.flightType === FlightTypes.OW) {
      const eventDate = SearchSchema.shape.dateRange
        .omit({ to: true })
        .safeParse({
          from: calendarSelector.propsExactDates.dateRange.from,
        });

      const event = SearchSchema.omit({ dateRange: true }).safeParse({
        departure: travelInfo.departure,
        destination: travelInfo.destination,
        rooms: travelInfo.rooms,
      });

      return {
        success: event.success && eventDate.success,
        error: event?.error?.flatten()?.fieldErrors,
      };
    }

    const event = SearchSchema.safeParse({
      departure: travelInfo.departure,
      destination: travelInfo.destination,
      rooms: travelInfo.rooms,
      dateRange: calendarSelector.propsExactDates.dateRange,
    });

    return {
      success: event.success,
      error: event?.error?.flatten()?.fieldErrors,
    };
  }

  return null;
};
