/* eslint-disable react-hooks/exhaustive-deps */
import { DocumentTitle } from '@/components/DocumentTitle';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { useIsMobile } from '@/hooks/useIsMobile';
import { usePushLandingData } from '@/hooks/usePushPlatformData/usePushLandingData';
import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { useStore } from '@/store/useStore';
import * as React from 'react';
import { Offers } from './Offers/Deals/Deals';
import { FavouriteDestinations } from './Offers/FavouriteDestinations/FavouriteDestinations';
import { TravelSearchInfo } from './TravelSearchInfo';
import { RecommendedSportEvents } from './Offers/RecommendedSportEvents/RecommendedSportEvents';

export function TravelSearch() {
  const [brandConfig, getDeals] = useStore((state) => [
    state.brandConfig,
    state.getDeals,
  ]);
  const { isTablet } = useIsMobile();

  const { sendTrack } = useTracker();

  const pushlandingData = usePushLandingData();

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH
    ? 'HGH'
    : brandConfig?.brand?.productTypesOrder?.[0];

  React.useEffect(() => {
    pushlandingData();
  }, []);

  React.useEffect(() => {
    sendTrack({
      trackName: 'travel-ui.onload-view',
      extraData: {
        view: 'search',
      },
      extraCommonData: {
        product,
      },
    });
  }, [sendTrack]);

  React.useEffect(() => {
    if (brandConfig.brand.businessProfileIdDP) {
      getDeals(brandConfig.brand.businessProfileIdDP);
    }
  }, [brandConfig.brand.businessProfileIdDP]);

  if (!brandConfig.landingContent) {
    return null;
  }

  const { hero, productExplanation } = brandConfig.landingContent;
  const offers = brandConfig.offers;

  return (
    <>
      <DocumentTitle title={brandConfig.brand.pageTitle} />
      <Header />
      <Hero
        title={hero.title}
        subtitle={hero.subtitle}
        backgroundUrl={hero.backgroundUrl}
        promoImage={isTablet ? hero?.promoImageMobile : hero?.promoImageDesktop}
      />
      {productExplanation && (
        <TravelSearchInfo
          title={productExplanation.title}
          subtitle={productExplanation.subtitle}
          description={productExplanation.description}
        />
      )}
      {offers && (
        <React.Fragment>
          <Offers />
          <FavouriteDestinations />
          <RecommendedSportEvents />
        </React.Fragment>
      )}
      <Footer />
    </>
  );
}
