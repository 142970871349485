import * as React from 'react';

export function useInView(options?: IntersectionObserverInit):[boolean,React.MutableRefObject<HTMLDivElement>] {
  const [isInView, setIsInView] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    const refCurrent = ref.current;

    if (refCurrent) {
      observer.observe(refCurrent);
    }

    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [options]);

  return [isInView, ref];
}
