import dayjs from 'dayjs';
import { TripObjectMap } from '../types/MappingTypes';
import { WayDetailsType } from '@/views/FlightsSearch/useGetTransports/types/tripDetailsTypes/WayDetailsType';
import { minutesToHours } from '@/utils/timeUtils';
import { StaticDataTransport } from '@/services/codegen-romulo';
import { getClassType } from './getFlightClass';

export function getFlightTripInDetails({
  flightProposalSelected,
  fProposalsStaticData,
}: {
  flightProposalSelected: {
    outbound: WayDetailsType;
    inbound?: WayDetailsType;
  };
  fProposalsStaticData: StaticDataTransport;
}): TripObjectMap['details'] {
  const outbound = flightProposalSelected.outbound;
  const inbound = flightProposalSelected.inbound;

  const outboundFlightNames = outbound.segmentsDetails
    .map((segment) => segment.transport.operativeId)
    .join('|');
  const inboundFlightNames = inbound?.segmentsDetails
    .map((segment) => segment.transport.operativeId)
    .join('|');

  const outboundDurationObj = minutesToHours(
    outbound.introInfo.durationMinutes,
    'number'
  ) as { hours: number; minutes: number };

  const inboundDurationObj = inbound
    ? (minutesToHours(inbound.introInfo.durationMinutes, 'number') as {
        hours: number;
        minutes: number;
      })
    : undefined;

  const destinationCountryCode =
    fProposalsStaticData.airports[outbound.segmentsDetails[0].arrival.hub.id]
      .countryId;

  const originCountryCode =
    fProposalsStaticData.airports[outbound.segmentsDetails[0].departure.hub.id]
      .countryId;

  const destinationCountryName =
    fProposalsStaticData.countries[destinationCountryCode].label;

  const originCountryName =
    fProposalsStaticData.countries[originCountryCode].label;

  const destinationContinentCode =
    fProposalsStaticData.countries[destinationCountryCode].continentId;

  const destinationContinentName =
    fProposalsStaticData.continents[destinationContinentCode];

  const originContinentCode =
    fProposalsStaticData.countries[originCountryCode].continentId;

  const originContinentName =
    fProposalsStaticData.continents[originContinentCode];

  const routeType =
    destinationCountryCode === originCountryCode
      ? 'domestic'
      : destinationContinentCode === originContinentCode
        ? 'continental'
        : 'interContinental';

  const outboundProviderType = outbound.segmentsDetails
    .map((segment) =>
      fProposalsStaticData.providers[segment.transport.companyCode].lowCost
        ? 'low cost'
        : 'gsd'
    )
    .join('|');

  const returnProviderType = inbound
    ? inbound.segmentsDetails
        .map((segment) =>
          fProposalsStaticData.providers[segment.transport.companyCode].lowCost
            ? 'low cost'
            : 'gsd'
        )
        .join('|')
    : undefined;

  const outboundClassKeys = outbound?.segmentsDetails.map(
    (s) => s.transport.class
  );
  const inboundClassKeys = inbound?.segmentsDetails.map(
    (s) => s.transport.class
  );

  const outboundClassTypes = outboundClassKeys
    .map((item) => getClassType(item))
    .join('|');
  const inboundClassTypes = inbound
    ? inboundClassKeys.map((item) => getClassType(item)).join('|')
    : undefined;

  const classTypes = inboundClassTypes
    ? outboundClassTypes + '|' + inboundClassTypes
    : outboundClassTypes;

  const durationMilliseconds = inbound
    ? dayjs(inbound.segmentsDetails[0].departure.datetime).diff(
        outbound.segmentsDetails[0].arrival.datetime
      )
    : undefined;

  const basicTrip = {
    class: classTypes,
    departureDate: dayjs(outbound.segmentsDetails[0].departure.datetime).format(
      'YYYYMMDD'
    ),
    duration: inbound
      ? Math.floor(durationMilliseconds / (1000 * 60 * 60 * 24))
      : undefined,
    name: inbound
      ? `${outboundFlightNames}|${inboundFlightNames}`
      : outboundFlightNames,
    outboundArrivalHour: dayjs(
      outbound.segmentsDetails[outbound.segmentsDetails.length - 1].arrival
        .datetime
    ).format('HHmm'),
    outboundDuration: Number(
      `${outboundDurationObj.hours < 10 ? '0' + outboundDurationObj.hours : outboundDurationObj.hours}${outboundDurationObj.minutes}`
    ),
    outboundOriginCode: outbound.segmentsDetails[0].departure.hub.id,
    outboundOriginName: outbound.segmentsDetails[0].departure.hub.name,
    outboundProviderId: outbound.segmentsDetails[0].transport.companyCode,
    outboundProviderName: outbound.segmentsDetails[0].transport.companyName,
    outboundStops: outbound.segmentsDetails.length - 1,
    returnArrivalHour: inbound
      ? dayjs(
          inbound.segmentsDetails[inbound.segmentsDetails.length - 1].arrival
            .datetime
        ).format('HHmm')
      : undefined,
    returnDate: inbound
      ? dayjs(
          inbound.segmentsDetails[inbound.segmentsDetails.length - 1].arrival
            .datetime
        ).format('YYYYMMDD')
      : undefined,
    returnDepartureHour: inbound
      ? dayjs(inbound.segmentsDetails[0].arrival.datetime).format('HHmm')
      : undefined,
    returnDepartureWeekDay: inbound
      ? dayjs(inbound.segmentsDetails[0].arrival.datetime)
          .format('ddd')
          .toLowerCase()
      : undefined,
    returnDestinationCode: inbound
      ? inbound.segmentsDetails[inbound.segmentsDetails.length - 1].arrival.hub
          .id
      : undefined,
    returnProviderId: inbound
      ? inbound.segmentsDetails[0].transport.companyCode
      : undefined,
    returnProviderName: inbound
      ? inbound.segmentsDetails[0].transport.companyName
      : undefined,
    returnStops: inbound ? inbound.segmentsDetails.length - 1 : undefined,
    roundTrip: !!inbound,
    destinationCountryCode,
    destinationCountryName,
    originCountryCode,
    originCountryName,
    destinationContinentCode,
    destinationContinentName,
    originContinentCode,
    originContinentName,
    outboundDestinationMacroCode: outbound.segmentsDetails[0].arrival.hub.id,
    returnDestinationMacroName: inbound?.segmentsDetails[0].arrival.hub.name,
    returnOriginMacroCode: inbound?.segmentsDetails[0].departure.hub.id,
    returnOriginMacroName: inbound?.segmentsDetails[0].departure.hub.name,
    returnProviderType,
    routeType,
    outboundDepartureHour: dayjs(
      outbound.segmentsDetails[0].departure.datetime
    ).format('HHmm'),
    outboundDepartureWeekDay: dayjs(
      outbound.segmentsDetails[0].departure.datetime
    )
      .format('ddd')
      .toLowerCase(),
    outboundDestinationCode: outbound.segmentsDetails[0].arrival.hub.id,
    outboundDestinationMacroName: outbound.segmentsDetails[0].arrival.hub.name,
    outboundDestinationName: outbound.segmentsDetails[0].arrival.hub.name,
    outboundOriginMacroCode: outbound.segmentsDetails[0].departure.hub.id,
    outboundOriginMacroName: outbound.segmentsDetails[0].departure.hub.id,
    outboundProviderType,
    returnDestinationMacroCode: inbound?.segmentsDetails[0].arrival.hub.id,
    returnDestinationName: inbound?.segmentsDetails[0].arrival.hub.name,
    returnDuration: inbound
      ? `${inboundDurationObj.hours < 10 ? '0' + inboundDurationObj.hours : inboundDurationObj.hours}${inboundDurationObj.minutes}`
      : undefined,
    returnOriginCode: inbound?.segmentsDetails[0].departure.hub.id,
    returnOriginName: inbound?.segmentsDetails[0].departure.hub.name,
  };

  return basicTrip;
}
