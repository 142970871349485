import {
  CartDetailResponseDto,
  PricingResponseDto,
} from '@/services/codegen-romulo';
import { ProductObjectMap } from '../types/MappingTypes';
import { getPricingAccommodation } from './getPricingAccommodation';
import { getPricingTrip } from './getPricingTrip';

export function getProductInfoDP<T extends 'details'>({
  pricingProposals,
  cartDetail,
  numberOfTravellers,
  bookingType,
}: {
  step: T;
  pricingProposals: PricingResponseDto;
  cartDetail: CartDetailResponseDto;
  numberOfTravellers: {
    adultNumber: number;
    childrenNumber: number;
    infantNumber: number;
    allChildrenAges: number[];
    numberOfRooms: number;
  };
  bookingType: string;
}): ProductObjectMap[T] {
  const numberOfGuests =
    numberOfTravellers.adultNumber +
    numberOfTravellers.childrenNumber +
    numberOfTravellers.infantNumber;
  const staticData = pricingProposals.staticData;

  const mealPlan = cartDetail?.products?.find((p) => p.type === 'HOTEL').detail
    .accommodation.rooms[0].mealPlanName;

  const outboundProviderCode =
    bookingType === 'dp' &&
    pricingProposals.proposal.transport.outbound.supplier.id;
  const outboundProviderName =
    bookingType === 'dp' &&
    pricingProposals.staticData.airlines.find(
      (airline) => airline.id === outboundProviderCode
    ).label;
  const outboundDepartureAirport =
    bookingType === 'dp' &&
    pricingProposals.proposal.transport.outbound.departureId;
  const outboundArrivalAirport =
    bookingType === 'dp' &&
    pricingProposals.proposal.transport.outbound.arrivalId;

  const returnProviderCode =
    bookingType === 'dp' &&
    pricingProposals.proposal.transport.inbound.supplier.id;
  const returnProviderName =
    bookingType === 'dp' &&
    pricingProposals.staticData.airlines.find(
      (airline) => airline.id === returnProviderCode
    ).label;
  const returnDepartureAirport =
    bookingType === 'dp' &&
    pricingProposals.proposal.transport.inbound.departureId;
  const returnArrivalAirport =
    bookingType === 'dp' &&
    pricingProposals.proposal.transport.inbound.arrivalId;

  const hotelName = pricingProposals.staticData.accommodation.label;

  const productBasicInfo = {
    infants: numberOfTravellers.infantNumber,
    name:
      bookingType === 'dp'
        ? `${outboundProviderName}_${outboundDepartureAirport}${outboundArrivalAirport}|${returnProviderName}_${returnDepartureAirport}${returnArrivalAirport}|${hotelName}`
        : bookingType === 'hotel'
          ? `${hotelName}`
          : '',
    numberOfGuests,
    accomodation: pricingProposals?.proposal?.accommodation
      ? getPricingAccommodation({
          staticData,
          numberOfRooms: numberOfTravellers.numberOfRooms,
          startDate: pricingProposals?.proposal?.accommodation.checkin,
          mealPlan,
        })
      : undefined,
    trip:
      bookingType === 'dp' && pricingProposals?.proposal?.transport
        ? getPricingTrip({
            staticData,
            transport: pricingProposals.proposal.transport,
          })
        : undefined,
  };

  return {
    ...productBasicInfo,
  };
}
