interface ScriptAttributes {
  [key: string]: string;
}

interface UseExternalScriptsReturn {
  addScript: ({
    url,
    attributes,
  }: {
    url: string;
    attributes: ScriptAttributes;
  }) => void;
}

export default function useExternalScripts(): UseExternalScriptsReturn {
  const head = document.querySelector('head') as HTMLHeadElement;

  const addScript = ({
    url,
    attributes,
  }: {
    url: string;
    attributes: ScriptAttributes;
  }): void => {
    if (document.querySelector(`script[src="${url}"]`)) {
      console.log(`Script already exists`);
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('src', url);

    Object.keys(attributes).forEach((att) => {
      script.setAttribute(att, attributes[att]);
    });

    head.appendChild(script);
  };

  return { addScript };
}
