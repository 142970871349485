import { useEvent } from '@/hooks/useEvent';
import * as React from 'react';
import { PropsTimeStayValue, TimeStayListContext } from '../Provider';
import * as Styled from './TimeStayList.styled';

type OnTimeStayProp = {
  value: PropsTimeStayValue;
  id: number;
  text: string;
};

export function TimeStayList({
  children,
  onTimeStay,
  timeStayId,
}: {
  children: React.ReactNode;
  onTimeStay?: (props: OnTimeStayProp) => void;
  timeStayId?: number; //OnTimeStayProp;
}) {
  const [currentTimeStayIndex, setCurrentTimeStayIndexs] = React.useState(
    timeStayId ?? 1
  );

  const onSelectTimeStay = useEvent(
    (value: PropsTimeStayValue, index: number, text: string) => {
      setCurrentTimeStayIndexs(index);
      onTimeStay?.({ id: index, value, text });
    }
  );

  const contextValues = React.useMemo(
    () => ({
      currentTimeStayIndex,
      onSelectTimeStay,
    }),
    [currentTimeStayIndex, onSelectTimeStay]
  );

  return (
    <TimeStayListContext.Provider value={contextValues}>
      <Styled.Container>{children}</Styled.Container>
    </TimeStayListContext.Provider>
  );
}
