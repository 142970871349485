import { MutableRefObject } from 'react';

export type CommonElementInjector = {
  inject: () => Promise<void>;
};

const reinjectScriptTags = (injectedElement: Element): void => {
  Array.from(injectedElement.querySelectorAll('script')).forEach((oldScript) => {
    const newScript = document.createElement('script');
    Array.from(oldScript.attributes).forEach((attr) => newScript.setAttribute(attr.name, attr.value));
    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    oldScript.parentNode?.replaceChild(newScript, oldScript);
  });
};

const fetchHtml = async (url: string): Promise<string> => (await fetch(url)).text();

export const createCommonElementInjector = (ref:MutableRefObject<HTMLDivElement | null>, url: string): CommonElementInjector => ({
  inject: async (): Promise<void> => {
    if (ref.current) {
      try{
        const reponseText = await fetchHtml(url);

        ref.current.innerHTML = reponseText
        
        reinjectScriptTags(ref.current);
      }catch(e){
        console.log("🚀 ~ createCommonElementInjector inject: ~ e:", e)
      }
    }
  },
});


