import { StrapiService } from '@/services/codegen-welfare';

export interface BrandConfigResponse {
  id: string;
  domain: string;
  brandConfig: any;
}

const brandConfigurationParams = [
  '*',
  'products',
  'brandDetails.footer',
  'brandDetails.footer.logo',
  'brandDetails.freeFlightConditions',
  'brandDetails.policy',
  'paymentMethods',
  'checkoutOptions.ancillaries',
  'landingContent',
  'landingContent.hero',
  'landingContent.hero.hero_background',
  'landingContent.hero.promo_img_desktop',
  'landingContent.hero.promo_img_mobile',
  'landingContent.productExplanation',
  'theme.favicon',
  'theme.logoPrimaryUrl',
  'theme.logoSecondaryUrl',
  'payment_methods',
  'hasTokenAuthentication',
  'checkoutOptions',
  'promo_campaign',
  'promo_campaign.bannerDP',
  'promo_campaign.bannerH',
  'promo_campaign.bannerF',
  'offers',
  'offers.dealList',
  'offers.dealList.images',
  'offers.destinationList',
  'offers.destinationList.image',
  'destinationProposal',
  'destinationProposal.destinationProposals',
  'destinationProposal.destinationProposals.imageUrl',
];

const brandConfigurationParamsString = brandConfigurationParams
  .map((value, index) => `populate[${index}]=${value}`)
  .join('&');

const hostname = window._env_.VITE_DOMAIN_STRAPI || window.location.hostname;

export const brandConfigurationPath = `brand-configurations/${hostname}?${brandConfigurationParamsString}`;

export const getInitialConfig = () =>
  StrapiService.getApiServicesAppStrapiGetConfig({
    path: brandConfigurationPath,
  });
