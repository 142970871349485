import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import { AirportSearch } from './components/AirportSearch/AirportSearch';
import { DestinationSearch } from './components/DestinationSearch/DestinationSearch';
import { ResultElementProps } from './components/SearchInput/ResultElement';
import { useGetDestinations } from './hooks/useGetDestinations';
import * as Styled from './LocationSelector.styled';
import { ProductType } from '../../Search.types';
import { useStore } from '@/store/useStore';

interface LocationSelectorProps {
  productType?: ProductType;
  onLocationSelector?: (values: Record<string, unknown>) => void;
  departure: { code: string } | null;
  errors: Record<string, unknown> | null;
  onError: (err: Record<string, unknown> | null) => void;
}

const LocationSelector: FC<LocationSelectorProps> = ({
  productType,
  onLocationSelector,
  departure,
  errors,
  onError,
}) => {
  const { sendTrack } = useTracker();

  const { t } = useTranslate();

  const getDestinations = useGetDestinations();

  const brandConfig = useStore((state) => state.brandConfig);

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH ? 'HGH' : productType;

  const onChangeDeparture = (departure: ResultElementProps | undefined) => {
    delete errors?.['departure'];

    onError(errors);

    onLocationSelector?.({ departure });
    if (departure) {
      sendTrack({
        trackName: 'selected.departure',
        logLevel: 'info',
        extraData: {
          title: departure?.title,
          product,
        },
        extraCommonData: {
          product,
        },
      });
    }
  };

  const onChangeDestination = async (
    destination: ResultElementProps | undefined
  ) => {
    try {
      delete errors?.['destination'];

      onError(errors);

      if (destination.type === 'Hotel') {
        onLocationSelector?.({ hotel: destination });
        if (destination.additionalInfo) {
          const city = destination.additionalInfo.split(',')[0];
          const basedCity = await getDestinations(city);
          onLocationSelector?.({ destination: basedCity[0] });
        }
      } else {
        onLocationSelector?.({ destination });
      }
    } catch (error) {
      onLocationSelector?.({ destination });
    }

    if (destination) {
      sendTrack({
        trackName: 'selected.destination',
        logLevel: 'info',
        extraData: {
          title: destination?.title,
          product,
        },
        extraCommonData: {
          product,
        },
      });
    }
  };

  const onTrack = (direction: string) => {
    sendTrack({
      trackName: `clicked.open-${direction}`,
      logLevel: 'info',
      extraData: {
        product,
      },
      extraCommonData: {
        product,
      },
    });
  };

  switch (productType) {
    case ProductType.F:
      return (
        <Styled.Container>
          <AirportSearch
            onChange={onChangeDeparture}
            label={t('search.departure')}
            handleOnClickInput={() => onTrack('departure')}
            placeholder={t('search.departure.placeholder')}
            error={errors?.['departure'] && t('search.departure.error')}
            direction={'OUTBOUND'}
            departure={departure}
          />
          <Styled.Divider />
          <AirportSearch
            onChange={onChangeDestination}
            handleOnClickInput={() => onTrack('destination')}
            label={t('search.destination')}
            placeholder={t('search.destination.placeholder')}
            error={errors?.['destination'] && t('search.destination.error')}
            direction={'INBOUND'}
            departure={departure}
          />
        </Styled.Container>
      );

    case ProductType.DP:
      return (
        <Styled.Container>
          <AirportSearch
            onChange={onChangeDeparture}
            handleOnClickInput={() => onTrack('departure')}
            label={t('search.departure')}
            placeholder={t('search.departure.placeholder')}
            error={errors?.['departure'] && t('search.departure.error')}
            direction={'OUTBOUND'}
            departure={departure}
          />
          <Styled.Divider />
          <DestinationSearch
            onChange={onChangeDestination}
            handleOnClickInput={() => onTrack('destination')}
            label={t('search.destination')}
            placeholder={t('search.destination.placeholder')}
            error={errors?.['destination'] && t('search.destination.error')}
          />
        </Styled.Container>
      );
    case ProductType.H:
      return (
        <DestinationSearch
          onChange={onChangeDestination}
          handleOnClickInput={() => onTrack('destination')}
          label={t('search.destination')}
          placeholder={t('search.destination.placeholder')}
          error={errors?.['destination'] && t('search.destination.error')}
        />
      );

    default:
      return null;
  }
};

export { LocationSelector };
