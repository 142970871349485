import {
  CartDetailResponseDto,
  TransportResponseAdvanceUpdated,
} from '@/services/codegen-romulo';
import { ProductObjectMap } from '../types/MappingTypes';
import { getFlightTripInDetails } from './getFlightTripInDetails';
import { WayDetailsType } from '@/views/FlightsSearch/useGetTransports/types/tripDetailsTypes/WayDetailsType';

export function getProductInfoF<T extends 'details'>({
  fProposals,
  numberOfTravellers,
  flightProposalSelected,
}: {
  step: T;
  fProposals: TransportResponseAdvanceUpdated;
  cartDetail: CartDetailResponseDto;
  numberOfTravellers: {
    adultNumber: number;
    childrenNumber: number;
    infantNumber: number;
    allChildrenAges: number[];
    numberOfRooms: number;
  };
  flightProposalSelected: {
    outbound: WayDetailsType;
    inbound?: WayDetailsType;
  };
}): ProductObjectMap[T] {
  const numberOfGuests =
    numberOfTravellers.adultNumber +
    numberOfTravellers.childrenNumber +
    numberOfTravellers.infantNumber;

  const outboundProviderName =
    flightProposalSelected.outbound.segmentsDetails[0].transport.companyName;

  const outboundDepartureAirport =
    flightProposalSelected.outbound.segmentsDetails[0].departure.hub.id;

  const outboundArrivalAirport =
    flightProposalSelected.outbound.segmentsDetails[0].arrival.hub.id;

  const returnProviderName = flightProposalSelected.inbound
    ? flightProposalSelected.inbound.segmentsDetails[0].transport.companyName
    : undefined;

  const returnDepartureAirport = flightProposalSelected.inbound
    ? flightProposalSelected.inbound.segmentsDetails[0].departure.hub.id
    : undefined;

  const returnArrivalAirport = flightProposalSelected.inbound
    ? flightProposalSelected.inbound.segmentsDetails[0].arrival.hub.id
    : undefined;

  const productBasicInfo = {
    infants: numberOfTravellers.infantNumber,
    name: flightProposalSelected?.inbound
      ? `${outboundProviderName}_${outboundDepartureAirport}${outboundArrivalAirport}|${returnProviderName}_${returnDepartureAirport}${returnArrivalAirport}`
      : `${outboundProviderName}_${outboundDepartureAirport}${outboundArrivalAirport}`,
    numberOfGuests,
    accomodation: undefined,
    trip: getFlightTripInDetails({
      flightProposalSelected,
      fProposalsStaticData: fProposals.staticData,
    }),
  };

  return {
    ...productBasicInfo,
  };
}
