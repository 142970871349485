import styled from '@emotion/styled';

export const FlashSaleOffersContainer = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  padding-top: 4em;
  overflow: hidden;
`;

export const FlashSaleOffersBackgroundImage = styled.div<{
  $fromLM: boolean;
  $backgroundColor: string;
}>`
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: ${({ $fromLM }) =>
    $fromLM
      ? "url('https://res.cloudinary.com/lastminute-contenthub/v1/DAM/Artwork/Deals/Last%20Minute%20deals_2024/MHP_background_desktop_final')"
      : ''};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  overflow: hidden;

  &:before {
    content: '';
    display: ${({ $fromLM }) => ($fromLM ? 'none' : 'block')};
    position: absolute;
    width: calc(100% + 200px);
    height: 290px;
    top: 20px;
    left: -100px;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    rotate: 1deg;
  }

  @media (max-width: 768px) {
    height: 600px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 32px;
  color: white;
  font-weight: 700;
`;
export const Subtitle = styled.span`
  font-size: 14px;
  color: white;
  font-weight: 400;
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const AtolImage = styled.div`
  background-image: url('https://res.cloudinary.com/lastminute-contenthub/v1/DAM/Artwork/Other/Certificates/ATOL/Atol_text_white');
  background-size: cover; /* Escala la imagen para cubrir el ancho completo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  width: 197px;
  height: 51px;
  min-width: 200px;
`;
