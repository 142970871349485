const lmnLogoUrl =
  'https://res.cloudinary.com/lastminute-contenthub/v1/DAM/Logos%20%2B%20fonts/SVG/corporate%20website/lastminute';

const volagratisLogoUrl =
  'https://res.cloudinary.com/lastminute-contenthub/v1/DAM/Logos%20%2B%20fonts/SVG/corporate%20website/volagratis_new_footer-white';

export const getLogo = (logo?: string) => {
  const hostName = window.location.hostname;
  const isLastminute = hostName.includes('lastminute.com');
  const isVolagratis = hostName.includes('volagratis.com');

  if (logo) {
    return <img src={logo} alt="page logo" height="50px" />;
  }

  if (isLastminute) {
    return <img src={lmnLogoUrl} alt="page logo" height="40px" />;
  }

  if (isVolagratis) {
    return <img src={volagratisLogoUrl} alt="page logo" height="60px" />;
  }

  return <img src={lmnLogoUrl} alt="page logo" height="40px" />;
};
