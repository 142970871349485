import { useIsMobile } from '@/hooks/useIsMobile';
import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { useStore } from '@/store/useStore';
import { ClickAwayListener } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as Locale from 'date-fns/locale';
import dayjs from 'dayjs';
import * as React from 'react';
import 'react-day-picker/style.css';
import * as Styled from './DateSelector.styled.tsx';
import { ProductType } from '../../Search.types.ts';
import { DateSelectorProps } from './DateSelector.types.ts';
import { Modal } from '@/components/Modal';
import { useDateSelectorEvents } from './useDateSelectorEvents.ts';
import { DatePicker } from './DatePicker/index.ts';
import { localeObject } from '@/utils/localeObjects.ts';

export function DateSelector({
  isOneWayTrip,
  dateRange,
  onSelectedDateRange,
  productType,
  errors,
  onError,
}: DateSelectorProps) {
  const [openDateSelector, setOpenDateSelector] = React.useState(false);

  const onCloseDatePicker = () => setOpenDateSelector(false);

  const { onSelect, onRemoveDateRefCurrent } = useDateSelectorEvents({
    dateRange,
    onSelectedDateRange,
    onCloseDatePicker,
    onError,
    errors,
    productType,
  });

  const { isMobile } = useIsMobile();

  const { sendTrack } = useTracker();

  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor, primaryColorBg } = brandConfig.theme;

  const { t } = useTranslate();

  const nightsAmount = dayjs(dateRange.to).diff(dateRange.from, 'days');

  const locale = Locale[brandConfig.language];

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH ? 'HGH' : productType;

  const handleOpenSelector = () => {
    setOpenDateSelector(true);

    sendTrack({
      trackName: 'opened.dates-selector',
      logLevel: 'info',
      extraData: {
        product,
      },
      extraCommonData: {
        product,
      },
    });
  };

  const handleCloseSelector = () => {
    const product = isProductHGH ? 'HGH' : productType;

    setOpenDateSelector(false);
    onRemoveDateRefCurrent();
    sendTrack({
      trackName: 'selected.dates',
      logLevel: 'info',
      extraData: {
        from: dateRange?.from?.format(),
        to: dateRange?.to?.format(),
        product,
      },
      extraCommonData: {
        product,
      },
    });
  };

  const valueTextField = isOneWayTrip
    ? `${dateRange?.from?.locale(brandConfig.language, localeObject[brandConfig.language]).format('ddd, DD MMM')}`
    : `${dateRange?.from?.locale(brandConfig.language, localeObject[brandConfig.language]).format('ddd, DD MMM')} - ${`${dateRange?.to?.locale(brandConfig.language, localeObject[brandConfig.language]).format('ddd, DD MMM')}`}`;

  return (
    <Styled.Container data-test="date-range-picker">
      <Styled.InnerContainer>
        <Styled.InputContainer>
          <Styled.Input
            label={t('search.when')}
            value={valueTextField}
            onClick={() => handleOpenSelector()}
            fullWidth
            autoComplete="off"
            slotProps={{ inputLabel: { shrink: true } }}
          />
          {errors?.['dateRange'] && (
            <Styled.Error>{t('search.dateRange.error')}</Styled.Error>
          )}
        </Styled.InputContainer>
      </Styled.InnerContainer>
      {openDateSelector && !isMobile && (
        <ClickAwayListener
          children={
            <Styled.CalendarContainer>
              <DatePicker
                isOneWayTrip={isOneWayTrip}
                onSelect={onSelect}
                dateRange={dateRange}
                primaryColor={primaryColor}
                primaryColorBg={primaryColorBg}
                locale={locale}
              />
              {productType !== ProductType.F && (
                <Styled.NightsCounter>
                  {nightsAmount}{' '}
                  {nightsAmount >= 2 ? t('general.nights') : t('general.night')}
                </Styled.NightsCounter>
              )}
            </Styled.CalendarContainer>
          }
          onClickAway={handleCloseSelector}
        />
      )}
      {isMobile && (
        <Modal
          title={t('search.dates')}
          isModalOpen={openDateSelector}
          fullHeight
          onCloseModal={handleCloseSelector}
          closable
        >
          <Styled.MobileCalendarContainer>
            <Styled.MobileDatesContainer>
              {!isOneWayTrip && (
                <Styled.Item $selected={false} $color={primaryColor}>
                  {dateRange?.from
                    ?.locale(
                      brandConfig.language,
                      localeObject[brandConfig.language]
                    )
                    .format('ddd, DD MMM')}
                </Styled.Item>
              )}
              <Styled.DatesDivider>-</Styled.DatesDivider>
              <Styled.Item $selected={false} $color={primaryColor}>
                {dateRange?.to
                  ?.locale(
                    brandConfig.language,
                    localeObject[brandConfig.language]
                  )
                  .format('ddd, DD MMM')}
              </Styled.Item>
            </Styled.MobileDatesContainer>
            <Styled.DateSelectorContainer>
              <DatePicker
                isOneWayTrip={isOneWayTrip}
                onSelect={onSelect}
                dateRange={dateRange}
                primaryColor={primaryColor}
                primaryColorBg={primaryColorBg}
                locale={locale}
              />
            </Styled.DateSelectorContainer>
          </Styled.MobileCalendarContainer>
        </Modal>
      )}
    </Styled.Container>
  );
}
