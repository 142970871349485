import { ProductType } from '@/components/Search/Search.types';
import { useStore } from '@/store/useStore';
import { useBookingType } from '../useBookingType';
import { LandingObject } from './types/LandingObject';
import { usePushPlatformData } from './usePushPlatformData';
import { getDeviceType } from './utils/getDeviceType';
import { mapObjectToPush } from './utils/mapObjectToPush';
import { getBookingType } from './utils/getBookingType';
import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { getCategoryLevel } from './utils/getCategoryLevel';

export const usePushLandingData = () => {
  const { pushLanding } = usePushPlatformData();

  const { sendTrack } = useTracker();

  const brandConfig = useStore((state) => state.brandConfig);
  const { brandBase, currency } = brandConfig.brand;
  const { language, country } = brandConfig;
  const deviceType = getDeviceType();
  const brandProduct = brandConfig?.brand?.productTypesOrder[0] as ProductType;
  const bookingType = useBookingType();
  const locale = `${language}_${country}`;
  const { businessProfileIdDP, businessProfileIdF, businessProfileIdH } =
    brandConfig.brand;

  const getBP = () => {
    switch (brandProduct) {
      case ProductType.F:
        return businessProfileIdF;
      case ProductType.DP:
        return businessProfileIdDP;
      case ProductType.H:
        return businessProfileIdH;
      default:
        return undefined;
    }
  };

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH ? 'HGH' : brandProduct;

  const pushlandingData = () => {
    try {
      const newObject: LandingObject = mapObjectToPush({
        transactionAffiliation: getBP(),
        step: 'hp',
        brandBase,
        bookingType: getBookingType(bookingType),
        locale,
        currency,
        deviceType,
        categoryLevel: getCategoryLevel(brandConfig),
      });

      pushLanding(newObject);
      sendTrack({
        trackName: 'visited.landing',
        logLevel: 'info',
        extraData: {
          info: newObject,
        },
        extraCommonData: {
          product,
        },
      });
    } catch (e) {
      console.log('Error creating platform data object', e);
    }
  };

  return pushlandingData;
};
