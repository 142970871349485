/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ToggleDefinition } from '../models/ToggleDefinition';
import type { UnleashFlag } from '../models/UnleashFlag';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeatureToogleService {

    /**
     * @returns UnleashFlag Success
     * @throws ApiError
     */
    public static postApiServicesAppFeatureToogleFlagProviderList(): CancelablePromise<UnleashFlag> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/FeatureToogle/FlagProviderList',
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppFeatureToogleIsFeatureEnabled({
        featureName,
    }: {
        featureName?: string,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/FeatureToogle/IsFeatureEnabled',
            query: {
                'featureName': featureName,
            },
        });
    }

    /**
     * @returns ToggleDefinition Success
     * @throws ApiError
     */
    public static postApiServicesAppFeatureToogleListKnowToggles(): CancelablePromise<Array<ToggleDefinition>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/FeatureToogle/ListKnowToggles',
        });
    }

}
