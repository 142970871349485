import { useGetTravellerGroups } from '@/hooks/useGetTravellerGroups';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { useStore } from '@/store/useStore';
import { AddCircle } from '@mui/icons-material';
import { ClickAwayListener, Divider, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, useEffect, useState } from 'react';
import { Modal } from '../Modal';
import * as Styled from './PassengerSelector.styled';
import { PassengersSelectorProps } from './PassengerSelector.types';
import { Room } from './components/Room';
import { CabinClass } from './components/CabinClass/CabinClass';
import cabinClasses from '../../utils/dictionaries/cabinClasses.json';

const MAX_AMOUNT_ROOMS = 6;
const MAX_AMOUNT_TRAVELLERS = 9;

export const PassengersSelector: FC<PassengersSelectorProps> = ({
  onPassengersSelector,
  onCabinClassSelector,
  productType,
  rooms,
  cabinClass,
}) => {
  console.log('🚀 ~ cabinClass:', cabinClass);
  const [open, setOpen] = useState(false);

  const [addedDefaultRooms, setAddedDefaultRooms] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { t } = useTranslate();

  const prevTravellersGroup = useGetTravellerGroups();

  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor } = brandConfig.theme;

  const { isMobile } = useIsMobile();

  const { sendTrack } = useTracker();

  useEffect(() => {
    if (!addedDefaultRooms) {
      prevTravellersGroup.rooms.length > 0 &&
        onPassengersSelector?.({ rooms: prevTravellersGroup.rooms });
      setAddedDefaultRooms(true);
    }
    onCabinClassSelector?.({ cabinClass: prevTravellersGroup.cabinClass || 'all' });
  }, []);

  const peopleSum = rooms.reduce((acc, curr) => {
    acc = acc + curr.adults;
    acc = acc + curr.childrenAges.length;
    return acc;
  }, 0);

  const getCabinClassLabel = () => {
    return t(cabinClasses[cabinClass] || cabinClasses['all']);
  };

  const getResultString = (searchType: string) => {
    switch (searchType) {
      case 'F':
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.passengers')
          : t('general.passenger')
        ).toLowerCase()} • ${getCabinClassLabel()}`;
      case 'DP':
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.travellers')
          : t('general.traveller')
        ).toLowerCase()} • ${rooms.length} ${(rooms.length > 1
          ? t('search.rooms')
          : t('search.room')
        ).toLowerCase()} • ${getCabinClassLabel()}`;
      case 'H':
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.guests')
          : t('general.guest')
        ).toLowerCase()} • ${rooms.length} ${(rooms.length > 1
          ? t('search.rooms')
          : t('search.room')
        ).toLowerCase()}`;
      default:
        return '';
    }
  };

  const getLabel = (searchType: string) => {
    switch (searchType) {
      case 'F':
        return t('search.passengers');
      case 'DP':
        return t('search.travellers');
      case 'H':
        return t('search.guests');
      default:
        return '';
    }
  };

  const handleAdult = (number: number, roomNumber: number) => {
    const result = rooms.map((room, index) =>
      index === roomNumber ? { ...room, adults: number } : room
    );

    onPassengersSelector({ rooms: result });
  };

  const handleChildren = (age: number, roomNumber: number) => {
    const result = rooms.map((room, index) =>
      index === roomNumber
        ? { ...room, childrenAges: room.childrenAges.concat(age) }
        : room
    );

    onPassengersSelector({ rooms: result });
  };

  const removeChild = (roomNumber: number, childIndex: number) => {
    const result = rooms.map((room, roomIndex) => {
      if (roomIndex === roomNumber) {
        const resultArray = room.childrenAges.filter(
          (_, index) => index !== childIndex
        );
        return { ...room, childrenAges: resultArray };
      }
      return room;
    });
    onPassengersSelector({ rooms: result });
  };

  const addRoom = () => {
    rooms.length < MAX_AMOUNT_ROOMS &&
      onPassengersSelector({
        rooms: rooms.concat([{ adults: 1, childrenAges: [] }]),
      });
  };

  const removeRoom = (roomNumber: number) => {
    const result = rooms.filter((_, index) => index !== roomNumber);
    onPassengersSelector({ rooms: result });
  };

  const canAddPassengers =
    rooms.reduce((acc, curr) => {
      const adults = acc + curr.adults;
      const children = curr.childrenAges.length;
      acc = adults + children;
      return acc;
    }, 0) < MAX_AMOUNT_TRAVELLERS;

  const canAddRoom =
    productType !== 'F' ? rooms.length < 6 && canAddPassengers : false;

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH ? 'HGH' : productType;

  const handleOpenModal = () => {
    sendTrack({
      trackName: 'opened.dates-selector',
      logLevel: 'info',
      extraData: {
        product,
      },
      extraCommonData: {
        product,
      },
    });
    if (isMobile) {
      setIsModalOpen(true);
      setOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const handleCloseSelector = () => {
    if (isMobile) {
      setIsModalOpen(false);
    } else {
      setOpen(false);
    }
    sendTrack({
      trackName: 'selected.rooms',
      logLevel: 'info',
      extraData: {
        rooms,
        product,
      },
      extraCommonData: {
        product,
      },
    });
  };

  return (
    <Styled.PassengerSelector data-test="passenger-selector">
      <TextField
        value={getResultString(productType)}
        slotProps={{ inputLabel: { shrink: true } }}
        sx={{
          width: '100%',
          '& label': {
            fontSize: '18px',
          },
          '& input': {
            textOverflow: 'ellipsis',
          },
        }}
        onClick={handleOpenModal}
        label={getLabel(productType)}
      />
      {open && (
        <ClickAwayListener
          children={
            <Styled.PassengersSelectorContent
              $isMobile={isMobile}
              $roomColumns={rooms.length < 3 ? rooms.length % 3 : 0} // 3 % 3 === 0, so 0 is 3 columns
            >
              <Styled.RoomsContainer>
                {rooms.map((_, roomNumber, arr) => (
                  <Room
                    key={roomNumber}
                    roomNumber={roomNumber}
                    adults={rooms[roomNumber].adults}
                    minors={rooms[roomNumber].childrenAges}
                    updateAdults={(number) => handleAdult(number, roomNumber)}
                    updateChild={(age) => handleChildren(age, roomNumber)}
                    removeChild={removeChild}
                    addRoom={() => addRoom()}
                    removeRoom={() => removeRoom(roomNumber)}
                    canAddRoom={canAddRoom}
                    canAddPassengers={canAddPassengers}
                    roomsAmount={arr.length}
                    productType={productType}
                  />
                ))}
              </Styled.RoomsContainer>
              {productType !== 'H' && (
                <>
                  <Divider style={{ borderColor: 'rgb(234, 234, 235)' }} />
                  <CabinClass
                    onCabinClassSelector={onCabinClassSelector}
                    cabinClass={cabinClass}
                  />
                </>
              )}
            </Styled.PassengersSelectorContent>
          }
          onClickAway={() => setOpen(false)}
        />
      )}
      <Modal
        title={t('search.travellers')}
        isModalOpen={isModalOpen}
        fullHeight
        onCloseModal={handleCloseSelector}
        closable
      >
        <Styled.MobileRoomsContainer>
          <Styled.PassengersSelectorContent $isMobile={isMobile}>
            {rooms.map((_, roomNumber, arr) => (
              <Room
                key={roomNumber}
                roomNumber={roomNumber}
                adults={rooms[roomNumber].adults}
                minors={rooms[roomNumber].childrenAges}
                updateAdults={(number) => handleAdult(number, roomNumber)}
                updateChild={(age) => handleChildren(age, roomNumber)}
                removeChild={removeChild}
                addRoom={() => addRoom()}
                removeRoom={() => removeRoom(roomNumber)}
                canAddRoom={canAddRoom}
                canAddPassengers={canAddPassengers}
                roomsAmount={arr.length}
                productType={productType}
              />
            ))}
          </Styled.PassengersSelectorContent>
          {canAddRoom && isMobile && (
            <Styled.AddRoomMobile>
              <Styled.AddRoomMobileButton
                style={{
                  color: canAddPassengers ? primaryColor : '#3c3c3c',
                }}
                onClick={addRoom}
              >
                <AddCircle />
                {t('search.add.room')}
              </Styled.AddRoomMobileButton>
            </Styled.AddRoomMobile>
          )}
          {productType !== 'H' && (
            <CabinClass
              onCabinClassSelector={onCabinClassSelector}
              cabinClass={cabinClass}
            />
          )}
        </Styled.MobileRoomsContainer>
      </Modal>
    </Styled.PassengerSelector>
  );
};
