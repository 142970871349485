import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useCurrencyFormatter } from '@/hooks/useCurrencyFormatter';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './FavouriteDestinations.styled';
import { generateUrl } from '../../../Deals/utils/generateUrl';

export const FavouriteDestinations: FC = () => {
  const [brandConfig, deals] = useStore((state) => [
    state.brandConfig,
    state.deals,
  ]);
  const { primaryColor } = brandConfig.theme;
  const { t } = useTranslate();
  const offers = brandConfig.offers;
  const currencyFormatter = useCurrencyFormatter();

  const destinationList = deals
    .filter((e) => e.feedType === 'Folder card')
    .slice(0, 3);

  if (isEmpty(destinationList)) {
    return null;
  }
  return (
    <Styled.FlashSaleOffersContainer>
      <Styled.InnerContainer>
        <Styled.TitleContainer>
          <div>
            <Styled.SectionTitle>
              {t(offers.favouriteDestinationsTitleKey)}
            </Styled.SectionTitle>
            <Styled.Subtitle>
              {t(offers.favouriteDestinationsSubtitleKey)}
            </Styled.Subtitle>
          </div>
        </Styled.TitleContainer>
        <Styled.OffersCarousel>
          {destinationList.map((e, key) => {
            const url = generateUrl(e);
            return (
              <Styled.DestinationLink href={url} key={key}>
                <Styled.DestinationContainer>
                  <Styled.DestinationImage url={e?.arrivalImage} />
                  <Styled.DetinationContent>
                    <Styled.Name>{e.arrivalName}</Styled.Name>
                    <Styled.Tag color={primaryColor}>
                      {t('general.price.from')}{' '}
                      {currencyFormatter.formatNumber(e.price)}
                    </Styled.Tag>
                  </Styled.DetinationContent>
                </Styled.DestinationContainer>
              </Styled.DestinationLink>
            );
          })}
        </Styled.OffersCarousel>
      </Styled.InnerContainer>
    </Styled.FlashSaleOffersContainer>
  );
};
