import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

const isDayjs = (day: Dayjs) => dayjs.isDayjs(day);

export const SearchSchema = z.object({
  rooms: z.array(
    z.object({ adults: z.number(), childrenAges: z.array(z.number()) })
  ),
  departure: z.object({ code: z.string() }).required(),
  destination: z.object({ code: z.string(), type: z.string() }),
  dateRange: z.object({
    to: z.custom<Dayjs>(isDayjs),
    from: z.custom<Dayjs>(isDayjs),
  }),
});
