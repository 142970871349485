import * as React from 'react';
import { useCommomTrackerData } from './useCommomTrackerData';
import { sendEventReciever } from './eventReceiver';
import { sendTrackProps } from './TrackerProvider.types';
import { TrackerProviderContext } from './useTracker';
import { getCookiesValuesCommon } from '@/providers/TrackerProvider/utils.ts';

const PROJECT_NAME = 'travel-ui';

export function TrackerProvider({ children }) {
  const commomTrackerData = useCommomTrackerData();

  const sendTrack = React.useCallback(
    ({
      trackName,
      logLevel = 'info',
      extraData = {},
      extraCommonData = {},
    }: sendTrackProps) => {
      const cookiesValuesCommon = getCookiesValuesCommon();

      sendEventReciever({
        eventName: trackName,
        logLevel,
        eventProperties: {
          ...extraData,
        },
        eventData: {
          ...commomTrackerData,
          ...extraCommonData,
          fingerprint: cookiesValuesCommon.fingerprint,
          sessionFingerprint: cookiesValuesCommon.sessionFingerprint,
        },
        project: PROJECT_NAME,
      });
    },
    [commomTrackerData]
  );

  const memoizedSendTrack = React.useMemo(() => ({ sendTrack }), [sendTrack]);

  return (
    <TrackerProviderContext.Provider value={memoizedSendTrack}>
      {children}
    </TrackerProviderContext.Provider>
  );
}
