import { useGetParam } from './useGetParam';

export function useGetTravellerGroups() {
  const getParam = useGetParam();

  const adults = getParam('adults');
  const childrenAges = getParam('childrenAges');

  const cabinClass = getParam('cabinClass');

  const adultsArray = adults?.split(';');
  const childrenAgesArray = childrenAges?.split(';');

  const rooms =
    adults && childrenAges
      ? adultsArray.map((adult, key) => {
          return {
            adults: Number(adult),
            childrenAges:
              childrenAgesArray[key] === 'none'
                ? []
                : childrenAgesArray[key].split(',').map((i) => Number(i)),
          };
        })
      : [];

  const adultNumber = rooms
    .map((room) => Number(room.adults))
    .reduce((a, b) => a + b, 0);

  const childrenNumber = rooms
    .map(
      (room) => room.childrenAges.filter((child) => Number(child) > 1).length
    )
    .reduce((a, b) => a + b, 0);

  const infantNumber = rooms
    .map(
      (room) => room.childrenAges.filter((child) => Number(child) <= 1).length
    )
    .reduce((a, b) => a + b, 0);

  const minorNumber = childrenNumber + infantNumber;

  const peopleNumber = adultNumber + minorNumber;

  const allChildrenAges = childrenAgesArray?.map((i) => i.split(',')).flat();

  

  return {
    rooms,
    adultNumber,
    childrenNumber,
    infantNumber,
    minorNumber,
    peopleNumber,
    allChildrenAges,
    cabinClass
  };
}
