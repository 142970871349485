import CookiesJS from 'js-cookie';

export class Cookies {
  static createCookie(key: string, value: string) {
    CookiesJS.set(key, value, {
      secure: true,
      sameSite: 'strict',
      //httponly: true,
    });
  }

  static getCookieValue(key: string) {
    return CookiesJS.get(key);
  }
}
