import { Favicon } from '@/components/Favicon';
import { AntdProvider } from '@/providers/AntdProvider/AntdProvider';
import { StoreProvider } from '@/store/storeProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Authentication } from './components/Authentication';
import { CookiesConsent } from './components/CookiesConsent/CookiesConsent';
import { DevTools } from './components/DevTools/DevTools';
import { LmnTealium } from './components/LmnTealium/LmnTealium';
import { Router } from './components/Router/Router';
import { useGetInitialConfig } from './hooks/useGetInitialConfig';
import { InternalFlagProvider } from './providers/InternalFlagProvider';
import { MuiProvider } from './providers/MuiProvider';
import { TolgeeProvider } from './providers/TolgeeProvider';
import { localeObject } from './utils/localeObjects';
import { EmotionProvider } from './providers/EmotionProvider';
import { TrackerProvider } from './providers/TrackerProvider';
import { useFingerprint } from '@/hooks/useFingerprint.ts';

dayjs.extend(utc);
dayjs.extend(timezone);

export function App() {
  const initialConfig = useGetInitialConfig();

  useFingerprint();

  React.useEffect(() => {
    if (!!initialConfig) {
      dayjs.locale(
        initialConfig?.language,
        localeObject[initialConfig?.language]
      );
    }
  }, [initialConfig]);

  if (!initialConfig) {
    return null;
  }

  return (
    <BrowserRouter>
      <StoreProvider initialState={initialConfig}>
        <TolgeeProvider>
          <AntdProvider>
            <MuiProvider>
              <EmotionProvider>
                <InternalFlagProvider>
                  <TrackerProvider>
                    <Router />
                    <Favicon />
                    <Authentication />
                    <CookiesConsent />
                    <LmnTealium />
                    <DevTools />
                  </TrackerProvider>
                </InternalFlagProvider>
              </EmotionProvider>
            </MuiProvider>
          </AntdProvider>
        </TolgeeProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}
