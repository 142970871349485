import styled from '@emotion/styled';
import { Chip as MuiChip } from '@mui/material';

export const CabinClassContainer = styled.div<{
  $isMobile: boolean;
}>`
  padding: 24px 16px 16px;
  width: /*${({ $isMobile }) => ($isMobile ? '100%' : '350px')};*/ 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '8px' : 0)};
  border-radius: ${({ $isMobile }) => ($isMobile ? '10px' : '0 0 8px 8px')};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  line-height: 20px;
  color: rgb(60, 60, 60);
  font-weight: 700;
  font-size: 16px;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Chip = styled(MuiChip)<{
  $isSelected: boolean;
  $primaryColor: string;
  $primaryColorBg: string;
}>`
  cursor: pointer;
  border: none;
  white-space: nowrap;
  user-select: none;
  transition: 0.2s;
  background: ${({ $isSelected, $primaryColorBg }) =>
    $isSelected ? $primaryColorBg : 'rgb(255, 255, 255)'};
  border-radius: 22px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 16px;
  -webkit-tap-highlight-color: transparent;
  color: ${({ $isSelected, $primaryColor }) =>
    $isSelected ? $primaryColor : 'rgb(60, 60, 60)'};
  height: auto;
  box-shadow: rgb(234, 234, 235) 0px 0px 0px 1px inset;

  :hover {
    box-shadow: ${({ $primaryColor }) =>
      $primaryColor + ' 0px 0px 0px 1px inset'};
    background-color: ${({ $isSelected, $primaryColorBg }) =>
      $isSelected ? $primaryColorBg : 'rgb(255, 255, 255)'};
  }
`;
