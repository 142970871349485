import { Dayjs } from 'dayjs';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { TravelInfoProps } from './Search';
import { FlexiDatesDataProps } from './useFlexiDates';

export function useSearchNavigate({
  productType,
  departure,
  destination,
  hotel,
  dateRange,
  rooms,
  token,
  isActiveMemSearch,
  flexiDatesRange,
  cabinClass,
}: TravelInfoProps & {
  dateRange: {
    from?: Dayjs;
    to?: Dayjs;
  };
  token?: string;
  isActiveMemSearch?: boolean;
  flexiDatesRange?: FlexiDatesDataProps;
  cabinClass?: string;
}) {
  const navigate = useNavigate();

  const childrenList = rooms
    .map((group) =>
      group.childrenAges.length === 0 ? 'none' : group.childrenAges
    )
    .join(';');

  const adultsList = rooms.map((group) => group.adults).join(';');

  const onNavigate = (searchId) => {
    if (isActiveMemSearch) {
      const adultsCount = rooms.reduce((acc, curr) => {
        acc = acc + curr.adults;
        return acc;
      }, 0);

      const minorsCount = rooms.reduce((acc, curr) => {
        acc = acc.concat(curr.childrenAges);
        return acc;
      }, []);

      const childrenCount = minorsCount.filter((e) => e >= 2).length;
      const infantsCount = minorsCount.filter((e) => e < 2).length;

      navigate({
        pathname: `/memSearch`,
        search: `?${createSearchParams({
          origin_memSearch: departure.id,
          destination_memSearch: destination.code,
          destinationName: destination.location || destination.name,
          dateFrom: flexiDatesRange.dateFrom,
          dateTo: flexiDatesRange.dateTo,
          adults: rooms.map((group) => group.adults).join(';'),
          adultsCount: `${adultsCount}`,
          childrenCount: `${childrenCount}`,
          infantsCount: `${infantsCount}`,
          adultsList,
          childrenList,
          cabinClass: cabinClass,
        })}`,
      });
      return;
    }

    if (searchId) {
      const aToken = token ? { a: token } : undefined;

      const hasDeparture = departure && productType !== 'H';

      const navigateQueryParams = {
        product: productType,
        searchId: searchId,
        departure: hasDeparture ? departure.id.toLocaleLowerCase() : undefined,
        departureType: hasDeparture ? departure.type : undefined,
        departureName: hasDeparture
          ? departure.location || departure.name
          : undefined,
        destinationId: destination.code.toLocaleLowerCase(),
        destinationType: destination.type,
        destinationName: destination.location || destination.name,
        hotelId: hotel ? hotel?.code?.toLocaleLowerCase() : undefined,
        hotelName: hotel ? hotel?.name : undefined,
        outboundDate: dateRange.from?.format('YYYYMMDD'),
        returnDate: dateRange.to?.format('YYYYMMDD'),
        adults: adultsList,
        mealPlan: 'ALL_MEAL_PLANS',
        'free-cancellation': 'false',
        sort: 'RECOMMENDED,true',
        page: 1,
        childrenAges: childrenList,
        showMap: 'true',
        cabinClass: cabinClass,
        ...aToken,
      };

      const filteredParams = Object.fromEntries(
        Object.entries(navigateQueryParams).filter(
          ([, value]) => value !== undefined
        )
      );

      navigate({
        pathname: `/${productType}`,
        search: `?${createSearchParams({
          ...filteredParams,
        })}`,
      });

      if (location.pathname !== '/') {
        window.location.reload();
      }
    }
  };
  return { onNavigate };
}
