import { ClickAwayListener } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import { ArrowDownIcon } from '../../LmnIcons/LmnIcons';
import * as Styled from '../PassengerSelector.styled';
import { refineAge } from '../utils/refineAge';

interface AddChildSelectorProps {
  updateChild: (age: number) => void;
  disabled: boolean;
  canAddInfant: boolean;
}

const AddChildSelector: FC<AddChildSelectorProps> = ({
  updateChild,
  disabled,
  canAddInfant,
}) => {
  const { t } = useTranslate();
  const [open, setOpen] = useState<boolean>(false);

  const handleUpdateChild = (age: number) => {
    updateChild(age);
    setOpen(false);
  };

  return (
    <Styled.AddChild>
      <Styled.AddChildButton
        onClick={() => setOpen(true)}
        disabled={disabled}
        id="search-form-add-passenger-child-selector"
      >
        <span>{t('search.add.child')}</span>
        <ArrowDownIcon size={24} />
      </Styled.AddChildButton>
      {open && (
        <ClickAwayListener
          children={
            <Styled.ChildAgesList>
              {Array.from({ length: 12 }, (_, i) => i).map((age, k) => {
                return !canAddInfant && (k === 0 || k === 1) ? (
                  <Styled.ChildAgesListItemDisabled
                    key={k}
                    value={age}
                    onClick={() => handleUpdateChild(age)}
                    className={`search-form-add-passenger-child-selector-${k === 0 || k === 1 ? 'infant' : 'child'}-disabled`}
                  >
                    {refineAge(age, 1, t)}
                  </Styled.ChildAgesListItemDisabled>
                ) : (
                  <Styled.ChildAgesListItem
                    key={k}
                    value={age}
                    onClick={() => handleUpdateChild(age)}
                    className={`search-form-add-passenger-child-selector-${k === 0 || k === 1 ? 'infant' : 'child'}`}
                  >
                    {refineAge(age, 1, t)}
                  </Styled.ChildAgesListItem>
                );
              })}
            </Styled.ChildAgesList>
          }
          onClickAway={() => setOpen(false)}
        ></ClickAwayListener>
      )}
    </Styled.AddChild>
  );
};

export { AddChildSelector };
