import { eventName } from './commonValues';
import { Input, Step, StepObjectMap } from '../types/MappingTypes';
import { getPageInfo } from './getPageInfo';
import { getUserInfo } from './getUserInfo';
import { getSearch } from './getSearch';
import { getProductInfoDP } from './getProductInfoDP';
import { getProductInfoF } from './getProductInfoF';

export function mapObjectToPush<T extends Step>(
  props: Input<T>
): StepObjectMap[T] {
  const event = eventName;

  const page = getPageInfo({
    step: props.step,
    brandBase: props.brandBase?.toLowerCase(),
    bookingType: props.bookingType,
    locale: props.locale,
    categoryLevel: props.categoryLevel,
  });

  const user = getUserInfo({
    step: props.step,
    currency: props.currency,
    deviceType: props.deviceType,
    accountId: undefined, // TODO We don't have it, we can create in the front, check it with LM
    hashedEmail: undefined,
  });

  const transactionAffiliation = props.transactionAffiliation;

  const results =
    props.step === 'results'
      ? {
          totalNumberOfResults:
            props.bookingType !== 'flight'
              ? props.hdpProposals?.facets.totalProposals
              : props.fProposals.facets.totalProposals,
          flashSales:
            props.bookingType !== 'flight'
              ? props.hdpProposals?.staticData.promotions.filter(
                  (e) => e.type === 'FLASH_SALE'
                ).length
              : undefined,
          cheapestPriceInPage: (
            (props.bookingType !== 'flight'
              ? props.hdpProposals.sorting.sorters.price.metadata.startingPrice
                  .std // TODO add cheapest price for flight
              : 0) / 100
          ).toString(),
        }
      : undefined;

  const search =
    props.step === 'results' &&
    getSearch({
      bookingType: props.bookingType,
      paramsData: props.paramsData,
      hdpProposals:
        props.bookingType !== 'flight' ? props.hdpProposals : undefined,
      fProposals: props.bookingType === 'flight' ? props.fProposals : undefined,
    });

  const productDP =
    props.bookingType !== 'flight'
      ? props.step === 'details' &&
        getProductInfoDP({
          step: props.step,
          numberOfTravellers: props.numberOfTravellers,
          pricingProposals: props.step === 'details' && props.pricingProposals,
          cartDetail: undefined,
          bookingType: props.bookingType,
        })
      : undefined;

  const productF =
    props.bookingType === 'flight'
      ? props.step === 'details' &&
        getProductInfoF({
          step: props.step,
          numberOfTravellers: props.numberOfTravellers,
          fProposals: props.step === 'details' && props.fProposals,
          cartDetail: undefined,
          flightProposalSelected:
            props.step === 'details' ? props.flightProposalSelected : undefined,
        })
      : undefined;

  switch (props.step) {
    case 'hp':
      return {
        event,
        page,
        user,
        transactionAffiliation,
      } as StepObjectMap['hp'];
    case 'results':
      return {
        event,
        page,
        user,
        transactionAffiliation,
        results,
        search,
      } as StepObjectMap['results'];
    case 'details':
      return {
        event,
        page,
        product: props.bookingType === 'flight' ? productF : productDP,
      } as StepObjectMap['details'];

    default:
      throw new Error('Invalid step');
  }
}
