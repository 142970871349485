import { PageObjectMap, Step } from '../types/MappingTypes';
import { pagePlatformName } from './commonValues';
import { getSection } from './getSection';

export function getPageInfo<T extends Step>({
  step,
  brandBase,
  bookingType,
  locale,
  categoryLevel,
}: {
  step: T;
  brandBase: string;
  bookingType: string;
  locale: string;
  categoryLevel: 'PCBB' | 'RWBB';
}): PageObjectMap[T] {
  const basicPage = {
    brand: brandBase,
    category: bookingType,
    locale: locale.toLowerCase(),
    platformName: pagePlatformName,
    platformVersion: window._env_.VERSION,
    section: getSection({ step, bookingType }),
    type: step,
  };

  switch (step) {
    case 'hp':
      return {
        ...basicPage,
        cluster: 'productHp',
      } as PageObjectMap['hp'];
    case 'results':
      return {
        ...basicPage,
        categoryLevelCode: categoryLevel,
        type: 'bkFlow',
      } as PageObjectMap['results'];
    case 'details':
      return {
        ...basicPage,
        categoryLevelCode: categoryLevel,
        type: 'bkFlow',
      } as PageObjectMap['details'];
    default:
      return basicPage;
  }
}
