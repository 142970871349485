import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useEffect, useMemo } from 'react';
import { Cookies } from '@/utils/cookies.ts';

export const useFingerprint = () => {
  const sessionFingerprint = useMemo(
    () => 'session-' + Math.random().toString(36).substring(2, 9),
    []
  );

  const getSessionFingerprint = () => {
    if (Cookies.getCookieValue('session-user-fingerprint')) {
      return;
    }

    Cookies.createCookie('session-user-fingerprint', sessionFingerprint);
  };

  const getFingerPrint = async () => {
    try {
      if (Cookies.getCookieValue('user-fingerprint')) {
        return;
      }

      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      Cookies.createCookie('user-fingerprint', visitorId);
    } catch (e) {
      console.log('[useFingerprint]: Failed to load]');
    }
  };

  useEffect(() => {
    getFingerPrint();
    getSessionFingerprint();
  }, []);
};
