import { useStore } from '@/store/useStore';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslate } from '@tolgee/react';
import { Divider } from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import cookiesTypes from '../../../../utils/dictionaries/cookiesTypes.json';
import * as Styled from './CookiesBanner.styled';
interface CookiesBannerModalProps {
  closeModal: () => void;
  acceptCookies: (acceptedCookies: 'all' | 'required' | string[]) => void;
}

const CookiesBannerModal: FC<CookiesBannerModalProps> = ({
  closeModal,
  acceptCookies,
}) => {
  const { t } = useTranslate();
  const brandConfig = useStore((s) => s.brandConfig);
  const cookiesPolicy = brandConfig.brand.policies.cookies;
  const { primaryColorBgTextColor, accentColor } = brandConfig.theme;

  const [selectedCookies, setSelectedCookies] = useState<string[]>([]);
  const [openedDescriptions, setOpenedDescriptions] = useState<string[]>([]);

  const { isMobile } = useIsMobile();

  useEffect(() => {
    const initialCookies = cookiesTypes.map((i) => i.key);
    setSelectedCookies(initialCookies);
  }, []);

  const mainColor = accentColor;
  const textColor = primaryColorBgTextColor;

  const updateSelectedCookies = (newValue: boolean, cookieType: string) => {
    const requiredCookies = cookiesTypes.map((i) => i.required && i.key);
    const newCookies = () => {
      if (requiredCookies.includes(cookieType)) {
        return selectedCookies;
      }

      if (selectedCookies.includes(cookieType)) {
        return newValue
          ? selectedCookies
          : selectedCookies.filter((i) => i !== cookieType);
      }

      return newValue ? selectedCookies.concat(cookieType) : selectedCookies;
    };

    setSelectedCookies(newCookies());
  };

  const ShowDescription = ({ type }) => (
    <Styled.ShowDescription
      onClick={() => {
        const newOpenedDescriptions = openedDescriptions.includes(type.key)
          ? openedDescriptions.filter((i) => i !== type.key)
          : openedDescriptions.concat(type.key);
        setOpenedDescriptions(newOpenedDescriptions);
      }}
    >
      {openedDescriptions.includes(type.key) ? (
        <>
          {t('cookies_consent.modal.button.hide_description')}
          <KeyboardArrowUpIcon />
        </>
      ) : (
        <>
          {t('cookies_consent.modal.button.show_description')}
          <KeyboardArrowDownIcon />
        </>
      )}
    </Styled.ShowDescription>
  );

  return (
    <Styled.ManageCookiesBanner>
      <Styled.ManageCookiesContainer>
        <Styled.ModalHeaderButtons>
          <Styled.BackButton onClick={closeModal}>
            <ChevronLeftIcon />
          </Styled.BackButton>
          {cookiesPolicy && (
            <Styled.FullDocumentButton href={cookiesPolicy} target="_blank">
              {t('cookies_consent.modal.button.show_full_cookies_policy')}
            </Styled.FullDocumentButton>
          )}
        </Styled.ModalHeaderButtons>
        <Styled.ModalContent>
          <Styled.CookiesSection>
            <Styled.Title>{t('cookies_consent.modal.main.title')}</Styled.Title>
            <Styled.Text>
              {t('cookies_consent.modal.main.description')}
            </Styled.Text>
            <Styled.AllCookiesButtons>
              <Styled.ButtonSecondary onClick={() => acceptCookies('required')}>
                <Styled.RejectIcon />
                {t('cookies_consent.modal.button.reject_all')}
              </Styled.ButtonSecondary>
              <Styled.ButtonPrimary
                $mainColor={mainColor}
                $textColor={textColor}
                $size="fit"
                onClick={() => acceptCookies('all')}
              >
                <Styled.AcceptIcon $color={textColor} />
                {t('cookies_consent.modal.button.accept_all')}
              </Styled.ButtonPrimary>
            </Styled.AllCookiesButtons>
          </Styled.CookiesSection>
          <Divider />
          <Styled.CookiesSection>
            <Styled.Title>
              {t('cookies_consent.modal.preferences.title')}
            </Styled.Title>
            <Styled.Text>
              {t('cookies_consent.modal.preferences.description')}
            </Styled.Text>
          </Styled.CookiesSection>
          <Divider />
          {cookiesTypes.map((type, key) => {
            return (
              <Fragment key={key}>
                <Styled.CookiesSection>
                  <Styled.CookieTypeSelector>
                    <Styled.CookieTypeTitle>
                      {t(type.name)}
                      {isMobile && <ShowDescription type={type} />}
                    </Styled.CookieTypeTitle>
                    <Styled.InfoAndSwitch>
                      {!isMobile && <ShowDescription type={type} />}
                      <Styled.Switch
                        checked={selectedCookies.includes(type.key)}
                        disabled={type.key === 'technical'}
                        onChange={(e, value) =>
                          updateSelectedCookies(value, type.key)
                        }
                        icon={
                          <CloseIcon style={{ fontSize: 20, color: '#ccc' }} />
                        }
                        checkedIcon={
                          <CheckIcon style={{ fontSize: 20, color: '#fff' }} />
                        }
                      />
                    </Styled.InfoAndSwitch>
                  </Styled.CookieTypeSelector>
                  {openedDescriptions.includes(type.key) && (
                    <Styled.Text>{t(type.description)}</Styled.Text>
                  )}
                </Styled.CookiesSection>
                <Divider />
              </Fragment>
            );
          })}
        </Styled.ModalContent>
        <Styled.FooterButtonContainer>
          <Styled.ButtonPrimary
            $mainColor={mainColor}
            $textColor={textColor}
            $size="fit"
            onClick={() => acceptCookies(selectedCookies)}
          >
            {t('cookies_consent.modal.button.save_and_continue')}
          </Styled.ButtonPrimary>
        </Styled.FooterButtonContainer>
      </Styled.ManageCookiesContainer>
    </Styled.ManageCookiesBanner>
  );
};

export default CookiesBannerModal;
