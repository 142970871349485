const formattedBoundMap = (bound) => {
  if (typeof bound === 'string') {
    return Number(bound);
  }

  return bound;
};

const formattedPayload = (key: string, payload: string[] | string) => {
  if (typeof payload === 'string' && payload) {
    return [
      {
        name: key,
        values: [payload],
      },
    ];
  }

  if (Array.isArray(payload) && payload.length > 0) {
    return [
      {
        name: key,
        values: payload,
      },
    ];
  }

  return [];
};

export const getHDPFacets = (filters: any) => {
  const priceRange =
    filters.priceRange?.length > 0
      ? [
          {
            name: 'price-range',
            values: [
              filters.priceRange[0].toString(),
              filters.priceRange[1].toString(),
            ],
          },
        ]
      : [];

  const deals = filters.deals
    ? [
        {
          name: 'promotion',
          values: ['FLASH_SALE'],
        },
      ]
    : [];

  const freeCancellation =
    filters['free-cancellation'][0] === 'true' ||
    filters['free-cancellation'] === 'true'
      ? formattedPayload('free-cancellation', ['true'])
      : [];

  const propertyTypes = formattedPayload('property-type', filters.propertyType);

  const stars = formattedPayload('stars', filters.stars);

  const rating = formattedPayload('opinion-rating', filters.rating);

  const amenities = formattedPayload('amenities', filters.amenities);

  const characteristics = formattedPayload(
    'characteristics',
    filters.characteristics
  );

  const mealPlans =
    filters.mealPlan && filters.mealPlan.length > 0
      ? [
          {
            name: 'meal-plan',
            values:
              filters.mealPlan === 'ALL_MEAL_PLANS'
                ? [filters.mealPlan]
                : typeof filters.mealPlan === 'string'
                  ? [filters.mealPlan]
                  : filters.mealPlan,
          },
        ]
      : [];

  const coordinates =
    filters.mapBoundingBox && filters.mapBoundingBox.length > 0
      ? [
          {
            name: 'coordinates',
            boundingBox: {
              from: {
                latitude: Number(
                  formattedBoundMap(filters.mapBoundingBox?.[0])?.toFixed(5)
                ),
                longitude: Number(
                  formattedBoundMap(filters.mapBoundingBox?.[3])?.toFixed(5)
                ),
              },
              to: {
                latitude: Number(
                  formattedBoundMap(filters.mapBoundingBox?.[2])?.toFixed(5)
                ),
                longitude: Number(
                  formattedBoundMap(filters.mapBoundingBox?.[1])?.toFixed(6)
                ),
              },
            },
          },
        ]
      : [];

  return [
    ...priceRange,
    ...deals,
    ...freeCancellation,
    ...propertyTypes,
    ...stars,
    ...rating,
    ...amenities,
    ...characteristics,
    ...mealPlans,
    ...coordinates,
  ];
};
