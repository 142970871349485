import { SearchIcon } from '@/components/LmnIcons/LmnIcons';
import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useGetParam } from '@/hooks/useGetParam';
import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { useStore } from '@/store/useStore';
import { Fragment } from 'react';
import { Search } from '../Search';
import { AuthenticationButton } from './AuthenticationButton/AuthenticationButton';
import { CallUsButton } from './CallButton';
import * as Styled from './Header.styled';
import { useGetInfoData } from './useGetInfoData';
import { SkeletonUI } from './Skeleton';

export function Header({
  showSearchSummary = false,
}: {
  showSearchSummary?: boolean;
}) {
  const [brandConfig, openHeaderSearch, setOpenHeaderSearch] = useStore(
    (state) => [
      state.brandConfig,
      state.openHeaderSearch,
      state.setOpenHeaderSearch,
    ]
  );

  const { theme, brand } = brandConfig;

  const bookingType = useBookingType();

  const { sendTrack } = useTracker();

  const getParam = useGetParam();

  const product = getParam('product');
  const aToken = getParam('a');

  const { headerColor, accentColor, logoPrimaryUrl } = theme;
  const { hasSSO } = brand;

  const { dateText, locationsText, passengersText, passengersFlightsText } =
    useGetInfoData(showSearchSummary);

  const handleLogoClick = () => {
    if (hasSSO && aToken) {
      window.location.href = `/?a=${aToken}`;
    } else {
      window.location.href = `/?product=${product}`;
    }
  };

  const handleSearchClick = () => {
    sendTrack({
      trackName: 'clicked.header.search',
      logLevel: 'info',
    });
    setOpenHeaderSearch(true);
  };

  return (
    <Fragment>
      <Styled.Container
        style={{
          backgroundColor: headerColor,
        }}
      >
        <Styled.LogoContainer
          $hideLogo={showSearchSummary && !openHeaderSearch}
          onClick={handleLogoClick}
        >
          <Styled.Logo src={logoPrimaryUrl} alt="logo" />
        </Styled.LogoContainer>

        {brandConfig.landingContent ? (
          <>
            {showSearchSummary && !openHeaderSearch && (
              <Styled.InnerContainer onClick={handleSearchClick}>
                <Styled.Text>{locationsText}</Styled.Text>
                <Styled.Divider />
                <Styled.Text>{dateText}</Styled.Text>
                <Styled.Divider />
                <Styled.Text>
                  {bookingType === BookingType.Flight
                    ? passengersFlightsText
                    : passengersText}
                </Styled.Text>
                <Styled.Button $backgroundcolor={accentColor}>
                  <SearchIcon size={16} />
                </Styled.Button>
              </Styled.InnerContainer>
            )}
          </>
        ) : (
          <SkeletonUI sx={{ width: '50%', height: 50, marginBottom: '8px' }} />
        )}

        {showSearchSummary && !openHeaderSearch && (
          <Styled.InnerContainerMobile onClick={handleSearchClick}>
            <Styled.MobileLocationText>
              {locationsText}
            </Styled.MobileLocationText>
            <Styled.MobileDatesText style={{ fontWeight: 400 }}>
              {dateText},
              {bookingType === BookingType.Flight
                ? passengersFlightsText
                : passengersText}
            </Styled.MobileDatesText>
          </Styled.InnerContainerMobile>
        )}
        <Styled.ExtraButtons>
          <CallUsButton />
          {hasSSO && <AuthenticationButton />}
        </Styled.ExtraButtons>
      </Styled.Container>
      {openHeaderSearch && (
        <Styled.SearchContainer>
          <Styled.Test>
            <Search
              disabledProductSelector
              onCloseSearch={() => setOpenHeaderSearch(false)}
              brandProduct={product}
            />
          </Styled.Test>
        </Styled.SearchContainer>
      )}
      {openHeaderSearch && (
        <Styled.GrayScreen onClick={() => setOpenHeaderSearch(false)} />
      )}
    </Fragment>
  );
}
