import { DealDto } from '@/services/codegen-welfare';
import dayjs from 'dayjs';

export const generateUrl = (deal: DealDto) => {
  const queryParams = new URLSearchParams(window.location.search);
  const affiliateId = queryParams.get('a');
  const url = new URL(`${window.location.origin}/DP`);
  url.searchParams.append('product', 'DP');
  url.searchParams.append('departure', deal.departure);
  url.searchParams.append('departureName', deal.departureName);
  url.searchParams.append('destinationId', deal.arrival.replace(/^TAG_/, ''));
  url.searchParams.append('destinationType', deal.arrivalType);
  url.searchParams.append('destinationName', deal.arrivalName.split(',')[0]);
  url.searchParams.append('hotelId', deal.hotelId);
  url.searchParams.append('hotelName', deal.hotelName);
  url.searchParams.append(
    'outboundDate',
    dayjs(deal.intervalStart).format('YYYYMMDD')
  );
  url.searchParams.append(
    'returnDate',
    dayjs(deal.intervalEnd).format('YYYYMMDD')
  );
  url.searchParams.append('adults', deal.travellersAdults.toString());
  // url.searchParams.append('childrenAges', deal.travellersChildren > 0 ? 'specified' : 'none');
  url.searchParams.append('childrenAges', 'none');

  if (affiliateId !== null) {
    url.searchParams.append('a', affiliateId);
  }

  return url.toString();
};
