import { generateDescription } from '@/views/Deals/utils/generateDescription';
import * as Styled from './OffersCarrousel.styled';
import { generateUrl } from '@/views/Deals/utils/generateUrl';
import { DealDto } from '@/services/codegen-welfare';
import { FC } from 'react';
import { HotelBedIcon, PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useTranslate } from '@tolgee/react';
import { Counter } from '@/components/Counter';
import PlaceIcon from '@mui/icons-material/Place';
import { Rating } from '@/components/HotelCard/Rating';
import { useStore } from '@/store/useStore';
import { useCurrencyFormatter } from '@/hooks/useCurrencyFormatter';
import { useNavigate } from 'react-router-dom';
import { useGetParam } from '@/hooks/useGetParam';

interface OffersCarrouselProps {
  dealList: DealDto[];
  quantityToShow: number | 'all';
  showLoadMoreButton?: boolean;
  mobileGrid: 'list' | 'carrousel';
}

export const OffersCarrousel: FC<OffersCarrouselProps> = ({
  dealList,
  quantityToShow,
  showLoadMoreButton,
  mobileGrid,
}) => {
  const getParam = useGetParam();

  const token = getParam('a');
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const language = brandConfig.language;
  const locale = brandConfig.language || 'en';
  const isFromLM = brandConfig.brand.brandOrigin === 'Lastminute';
  const primaryColor = brandConfig.theme.primaryColor;

  const currencyFormatter = useCurrencyFormatter();

  const navigate = useNavigate();

  return (
    <>
      <Styled.OffersCarousel $mobileGrid={mobileGrid}>
        {(quantityToShow === 'all'
          ? dealList
          : dealList.slice(0, quantityToShow)
        ).map((e, key) => {
          const description = generateDescription(e, t, locale);
          const url = generateUrl(e);
          return (
            <Styled.OfferContainer key={key} $mobileGrid={mobileGrid}>
              <Styled.ImagesCarouselContainer>
                <Styled.OfferType>
                  <PlaneNEIcon style={{ marginRight: '2px' }} />
                  <HotelBedIcon style={{ marginRight: '8px' }} />
                  {t(`general.product.dp`)}
                </Styled.OfferType>
                <Styled.CounterContainer>
                  <Counter date={e.lastMinuteDealExpirationDate} />
                </Styled.CounterContainer>
                <Styled.Location>
                  <PlaceIcon sx={{ fontSize: 16, marginRight: '4px' }} />
                  {e.arrivalName}
                </Styled.Location>
                <Styled.ImagesCarousel
                  images={e.hotelImages}
                  hideNavigation={false}
                />
              </Styled.ImagesCarouselContainer>
              <Styled.OfferContent href={url}>
                <Styled.OfferName>{e.hotelDescription}</Styled.OfferName>
                <Styled.HotelName>
                  {e.hotelName}{' '}
                  <Rating stars={e.hotelStars as 1} language={language} />
                </Styled.HotelName>
                <Styled.Details>{description}</Styled.Details>
                <Styled.PriceContainer
                  $fromLM={isFromLM}
                  $textColor={primaryColor}
                >
                  {t('general.price.from')}{' '}
                  <Styled.Price>
                    {currencyFormatter.formatNumber(e.price)}
                  </Styled.Price>{' '}
                  {t('general.price.pp')}
                </Styled.PriceContainer>
              </Styled.OfferContent>
            </Styled.OfferContainer>
          );
        })}
      </Styled.OffersCarousel>
      {showLoadMoreButton && (
        <Styled.LoadMoreButton
          $textColor={primaryColor}
          onClick={() => navigate(`/deals${token ? '?a=' + token : ''}`)}
        >
          {t('general.actions.load_more')}
        </Styled.LoadMoreButton>
      )}
    </>
  );
};
