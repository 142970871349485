import { DealDto } from '@/services/codegen-welfare';
import { localeObject } from '@/utils/localeObjects';
import { calcNightStays } from '@/utils/timeUtils';
import dayjs from 'dayjs';

export const generateDescription = (
  deal: DealDto,
  t: (key: string) => string,
  locale: string
) => {
  const nightsCount = calcNightStays(deal.intervalStart, deal.intervalEnd);
  const departure = dayjs(deal.intervalStart)
    .locale(locale, localeObject[locale])
    .format('DD MMM');
  const arrival = dayjs(deal.intervalEnd)
    .locale(locale, localeObject[locale])
    .format('DD MMM');

  return `${nightsCount} ${t('general.nights')} · ${departure} - ${arrival} ${t('general.flights.included')} · ${deal.departureName}`;
};
