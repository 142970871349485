import { ProposalTransport, StaticData } from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import { TripObjectMap } from '../types/MappingTypes';
import { getClassType } from './getFlightClass';

export function getPricingTrip<T extends 'details'>({
  transport,
  staticData,
}: {
  staticData: StaticData;
  transport: ProposalTransport;
}): TripObjectMap[T] {
  const findAirportInfo = (airportId: string | null | undefined) => {
    const airport = staticData?.airports?.find((a) => a.id === airportId);
    const country = staticData?.countries?.find(
      (c) => c.id === airport?.countryId
    );
    const continent = staticData?.continents?.find(
      (c) => c.id === country?.continentId
    );
    return { airport, country, continent };
  };

  const outboundDepartureInfo = findAirportInfo(
    transport?.outbound?.departureId
  );
  const outboundArrivalInfo = findAirportInfo(transport?.outbound?.arrivalId);
  const inboundDepartureInfo = findAirportInfo(transport?.inbound?.departureId);
  const inboundArrivalInfo = findAirportInfo(transport?.inbound?.arrivalId);

  const outboundAirline = staticData?.airlines?.find(
    (a) => a.id === transport?.outbound?.supplier?.id
  );
  const inboundAirline = staticData?.airlines?.find(
    (a) => a.id === transport?.inbound?.supplier?.id
  );

  const outboundFlightNames = transport.outbound.segments.map(
    (stop) => stop.supplier.carrierId
  );
  const inboundFlightNames = transport.inbound.segments.map(
    (stop) => stop.supplier.carrierId
  );

  const flightNames = outboundFlightNames.concat(inboundFlightNames).join('|');

  const outboundClassKeys = transport.outbound?.segments.map(
    (s) => s.classAvailability
  );
  const inboundClassKeys = transport.outbound?.segments.map(
    (s) => s.classAvailability
  );

  const outboundClassTypes = outboundClassKeys
    .map((item) => getClassType(item))
    .join('|');
  const inboundClassTypes = inboundClassKeys
    .map((item) => getClassType(item))
    .join('|');

  const classTypes = outboundClassTypes + '|' + inboundClassTypes;

  const durationMilliseconds = dayjs(
    transport.inbound?.isoZonedDepartureDateTime
  ).diff(transport.outbound?.isoZonedDepartureDateTime);

  const basicTrip = {
    class: classTypes,
    departureDate: dayjs(transport.outbound?.isoZonedDepartureDateTime).format(
      'YYYYMMDD'
    ),
    duration: Math.floor(durationMilliseconds / (1000 * 60 * 60 * 24)),
    name: flightNames,
    outboundArrivalHour: transport.outbound?.isoZonedArrivalDateTime
      ? dayjs(transport.outbound.isoZonedArrivalDateTime).format('HHmm')
      : undefined,
    outboundDuration: Number(`${transport.outbound?.durationInMinutes}`),
    outboundOriginCode: transport.outbound?.departureId,
    outboundOriginName: outboundDepartureInfo.airport?.label,
    outboundProviderId: transport.outbound?.supplier?.id,
    outboundProviderName:
      outboundAirline?.label || transport.outbound?.supplier?.operatorId,
    outboundStops: transport.outbound?.segments?.length
      ? transport.outbound.segments.length - 1
      : undefined,
    returnArrivalHour: transport.inbound?.isoZonedArrivalDateTime
      ? dayjs(transport.inbound.isoZonedArrivalDateTime).format('HHmm')
      : undefined,
    returnDate: dayjs(transport.inbound?.isoZonedDepartureDateTime).format(
      'YYYYMMDD'
    ),
    returnDepartureHour: transport.inbound?.isoZonedDepartureDateTime
      ? dayjs(transport.inbound.isoZonedDepartureDateTime).format('HHmm')
      : undefined,
    returnDepartureWeekDay: transport.inbound?.isoZonedDepartureDateTime
      ? dayjs(transport.inbound.isoZonedDepartureDateTime)
          .format('ddd')
          .toLowerCase()
      : undefined,
    returnDestinationCode: transport.inbound?.arrivalId,
    returnProviderId: transport.inbound?.supplier?.id,
    returnProviderName:
      inboundAirline?.label || transport.inbound?.supplier?.operatorId,
    returnStops: transport.inbound?.segments?.length
      ? transport.inbound.segments.length - 1
      : undefined,
    roundTrip: !!transport.inbound,
    destinationCountryCode: outboundArrivalInfo.country?.id,
    destinationCountryName: outboundArrivalInfo.country?.label,
    originCountryCode: outboundDepartureInfo.country?.id,
    originCountryName: outboundDepartureInfo.country?.label,
    destinationContinentCode: outboundArrivalInfo.continent?.id,
    destinationContinentName: outboundArrivalInfo.continent?.label,
    originContinentCode: outboundDepartureInfo.continent?.id,
    originContinentName: outboundDepartureInfo.continent?.label,
    outboundDestinationMacroCode: outboundArrivalInfo.airport?.id,
    returnDestinationMacroName: inboundArrivalInfo.airport?.label,
    returnOriginMacroCode: inboundDepartureInfo.airport?.id,
    returnOriginMacroName: inboundDepartureInfo.airport?.label,
    returnProviderType: transport.inbound?.transportType,
    routeType: transport.type,
  };

  return basicTrip;
}
