import { ImagesCarousel as MuiImagesCarousel } from '@/components/ImagesCarousel';
import styled from '@emotion/styled';
import { Button as ButtonMui, css } from '@mui/material';

export const OffersCarousel = styled.div<{ $mobileGrid: 'list' | 'carrousel' }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 768px) {
    ${({ $mobileGrid }) =>
      $mobileGrid === 'carrousel'
        ? css`
            width: 100%;
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            gap: 0px;
            scroll-snap-type: x mandatory;

            & > div {
              flex: 0 0 auto;
              @media (max-width: 576px) {
                width: calc(100% - 100px);
              }
              width: 100%;
              scroll-snap-align: start;
            }

            -ms-overflow-style: none; /* IE y Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
              display: none;
            }
          `
        : css`
            grid-template-columns: repeat(1, 1fr);
          `};
  }
`;

export const OfferContainer = styled.div<{ $mobileGrid: 'list' | 'carrousel' }>`
  @media (max-width: 768px) {
    padding-left: ${({ $mobileGrid }) =>
      $mobileGrid === 'carrousel' ? '16px' : 0};
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ImagesCarouselContainer = styled.div`
  position: relative;
`;

export const OfferType = styled.div`
  background-color: #000000cc;
  border-radius: 6px;
  height: 34px;
  align-items: center;
  min-width: unset;
  color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  padding: 5px 8px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
`;

export const CounterContainer = styled.div`
  position: absolute;
  top: 52px;
  right: 16px;
  z-index: 2;
`;

export const Location = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  color: white;
  bottom: 16px;
  left: 16px;
  z-index: 2;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const ImagesCarousel = styled(MuiImagesCarousel)`
  width: 100% !important;
  height: 320px;

  & > div:last-child {
    text-align: right;
    bottom: 14px !important;
    right: 8px;
  }
`;

export const OfferContent = styled.a`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: #3c3c3c;

  padding: 8px;
`;

export const OfferName = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HotelName = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #8c8c92;
  display: flex;
  gap: 0px 8px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Details = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;

export const PriceContainer = styled.span<{
  $fromLM: boolean;
  $textColor: string;
}>`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${({ $fromLM, $textColor }) => ($fromLM ? '#f2007d' : $textColor)};
`;
export const Price = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-right: 4px;
  margin-left: 4px;
`;

export const LoadMoreButton = styled(ButtonMui)<{
  $textColor: string;
}>`
  background-color: white;
  border-color: none;
  color: ${({ $textColor }) => $textColor};
  white-space: nowrap;
  width: fit-content;
  border: 1px solid;
  border-radius: 3px;
  padding: 5px 50px;
  margin: auto;
`;
