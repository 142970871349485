import { EventReceiverInputDto } from '@/services/codegen-welfare';

const EVENT_RECIVE_URL = window._env_.VITE_EVENT_R_URL;

export async function sendEventReciever(event: EventReceiverInputDto) {
  await fetch(EVENT_RECIVE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Special-Header': window._env_.VITE_EVENT_R_TOKEN,
    },
    body: JSON.stringify(event),
  });
}
