import { SearchIcon } from '@/components/LmnIcons/LmnIcons';
import { PassengersSelector } from '@/components/PassengerSelector';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useTracker } from '@/providers/TrackerProvider/useTracker';
import { useStore } from '@/store/useStore';
import { FlightTypes } from '@/utils/types/FlightTypes';
import { CircularProgress } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import { FlightTypeSelector } from './components/FlightTypeSelector';
import { LocationSelector } from './components/LocationSelector/LocationSelector';
import { ProductSelector } from './components/ProductSelector';
import * as Styled from './Search.styled';
import { ProductType } from './Search.types';
import { DateSelector } from './components/DateSelector/DateSelector';
import { useGetParam } from '@/hooks/useGetParam';
import { CalendarSelector } from './CalendarSelector';
import { useCalendarSelector } from './useCalendarSelector';
import { isValidateSearch } from './isValidateSearch';
import { useSearchId } from '@/hooks/useSearchId';
import { TravellerGroup } from '@/services/codegen-romulo';
import { ResultElementProps } from './components/LocationSelector/components/SearchInput/ResultElement';
import { useSearchNavigate } from './useSearchNavigate';
import { CabinType } from '@/utils/types/CabinClass';

export type TravelInfoProps = {
  departure: ResultElementProps | null;
  destination: ResultElementProps;
  hotel: ResultElementProps | null;
  productType: ProductType;
  rooms: TravellerGroup[];
  flightType: FlightTypes | null;
  cabinClass: CabinType;
};

export function Search({
  onCloseSearch,
  brandProduct,
  disabledProductSelector,
}: {
  onCloseSearch?: () => void;
  brandProduct?: ProductType;
  disabledProductSelector?: boolean;
}) {
  const { t } = useTranslate();

  const [isLoading, setisloading] = React.useState(false);

  const getParam = useGetParam();

  const freeFlightCampaign = useFreeFlightCampaign();

  const [travelInfo, setTravelInfo] = React.useState<TravelInfoProps>(() => ({
    departure: null,
    destination: null,
    hotel: null,
    productType: brandProduct,
    rooms: [
      {
        adults:
          freeFlightCampaign?.adults || brandProduct === ProductType.F ? 1 : 2,
        childrenAges: [],
      },
    ],
    flightType:
      getParam('outboundDate') && !getParam('returnDate')
        ? FlightTypes.OW
        : FlightTypes.R,
    cabinClass: 'all',
  }));

  React.useEffect(() => {
    console.log('🚀 ~ travelInfo:', travelInfo);
  }, [travelInfo]);

  const [errors, setErrors] = React.useState(null);

  const onError = (err) => setErrors(err);

  const { sendTrack } = useTracker();

  const brandConfig = useStore((state) => state.brandConfig);

  const { accentColor } = brandConfig.theme;

  const { isFreeFlight, isActiveMemSearch } = freeFlightCampaign;
  console.log('🚀 ~ isActiveMemSearch:', isActiveMemSearch);

  const isProductHGH =
    brandConfig.brand.isTopHotelProductType &&
    brandConfig?.brand?.productTypesOrder.length === 1 &&
    brandConfig?.brand?.productTypesOrder.includes('H');

  const product = isProductHGH ? 'HGH' : travelInfo.productType;

  const isOneWayTrip =
    travelInfo.productType === ProductType.F &&
    travelInfo.flightType === FlightTypes.OW;

  const propsCalendarSelector = useCalendarSelector({
    brandConfig,
    isOneWayTrip,
    language: brandConfig.language,
    onChangeFlightType: (flightType) =>
      setTravelInfo((s) => ({ ...s, flightType })),
    isActiveMemSearch,
  });

  const { getSearchId } = useSearchId({
    ...travelInfo,
    dateRange: propsCalendarSelector.propsExactDates.dateRange,
  });

  const { onNavigate } = useSearchNavigate({
    ...travelInfo,
    dateRange: propsCalendarSelector.propsExactDates.dateRange,
    flexiDatesRange: propsCalendarSelector.propsFlexiDates.flexiDatesData,
    isActiveMemSearch,
    token: getParam('a'),
    cabinClass: travelInfo.cabinClass,
  });

  const onSearch = async () => {
    const validate = isValidateSearch(travelInfo, propsCalendarSelector);
    //NOTE: check validate when M-Search exist
    console.log('validate', validate.success);
    console.log('isActiveMemSearch', isActiveMemSearch);
    if (validate.success || isActiveMemSearch) {
      onError(null);

      setisloading(true);

      const searchId = isActiveMemSearch ? null : await getSearchId();

      //NOTE: old track name is clicked.search-button

      setisloading(false);

      if (searchId || isActiveMemSearch) {
        sendTrack({
          trackName: 'travel-ui.search-button.click',
          logLevel: 'info',
          extraData: {
            departure: travelInfo.departure,
            destination: travelInfo.destination,
            dateRange: propsCalendarSelector.propsExactDates.dateRange,
            rooms: travelInfo.rooms,
            product,
            searchId,
          },
          extraCommonData: {
            product,
          },
        });

        onNavigate(searchId);
      } else {
        sendTrack({
          trackName: 'travel-ui.search-button.error',
          logLevel: 'error',
          extraData: {
            departure: travelInfo.departure,
            destination: travelInfo.destination,
            dateRange: propsCalendarSelector.propsExactDates.dateRange,
            rooms: travelInfo.rooms,
            product,
          },
          extraCommonData: {
            product,
          },
        });
      }

      onCloseSearch?.();
    } else {
      onError(validate.error);
    }
  };

  const onChangeTravelInfo = (values: Record<string, unknown>) => {
    setTravelInfo((s) => ({ ...s, ...values }));
  };

  return (
    <>
      {brandConfig.brand.productsInSelector.length > 0 &&
        !isFreeFlight &&
        !disabledProductSelector && (
          <ProductSelector
            productType={travelInfo.productType}
            onSelectorTab={(productType: ProductType) =>
              setTravelInfo((s) => ({ ...s, productType }))
            }
          />
        )}
      {travelInfo.productType === ProductType.F && !isFreeFlight && (
        <Styled.FlightOptions>
          <FlightTypeSelector
            flightType={travelInfo.flightType}
            onFlightSelector={
              propsCalendarSelector.propsExactDates.onFlightSelector
            }
          />
        </Styled.FlightOptions>
      )}
      <Styled.SearchContainer $isFreeFlight={isFreeFlight}>
        <LocationSelector
          productType={isFreeFlight ? ProductType.F : travelInfo.productType}
          onLocationSelector={onChangeTravelInfo}
          departure={travelInfo.departure}
          errors={errors}
          onError={onError}
        />
        {isActiveMemSearch && (
          <CalendarSelector
            calendarSelectorText={propsCalendarSelector.calendarSelectorText}
            onClickCalendarSelectorInput={
              propsCalendarSelector.onClickCalendarSelectorInput
            }
            propsFlexiDates={propsCalendarSelector.propsFlexiDates}
            isOpenCalendarSelector={
              propsCalendarSelector.isVisibleCalendarSelector
            }
            onChangeVisibleFlexiDates={
              propsCalendarSelector.onChangeVisibleFlexiDates
            }
            onCloseCalendarSelector={
              propsCalendarSelector.closeCalendarSelector
            }
          />
        )}
        {!isActiveMemSearch && (
          <DateSelector
            isOneWayTrip={isOneWayTrip}
            dateRange={propsCalendarSelector.propsExactDates.dateRange}
            onSelectedDateRange={
              propsCalendarSelector.propsExactDates.onSelectedDateRange
            }
            productType={travelInfo.productType}
            errors={errors}
            onError={onError}
          />
        )}
        {!isFreeFlight && (
          <PassengersSelector
            onPassengersSelector={onChangeTravelInfo}
            onCabinClassSelector={onChangeTravelInfo}
            rooms={travelInfo.rooms}
            productType={travelInfo.productType}
            cabinClass={travelInfo.cabinClass}
          />
        )}
        <Styled.Button
          onClick={onSearch}
          disabled={false}
          data-test="search-form-search-button"
          $color={accentColor}
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                color: 'white',
                width: '24px !important',
                height: '24px !important',
              }}
            />
          ) : (
            <SearchIcon size={24} />
          )}
          <Styled.SearchText>{t('search.search')}</Styled.SearchText>
        </Styled.Button>
      </Styled.SearchContainer>
    </>
  );
}
