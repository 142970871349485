import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonBase, buttonBaseClasses } from '@mui/material';

export const PassengersSelectorContent = styled.div<{
  $isMobile: boolean;
  $roomColumns?: number;
}>`
  position: ${({ $isMobile }) => ($isMobile ? 'relative' : 'absolute')};
  right: 0px;
  display: flex;
  flex-wrap: wrap;
  width: ${({ $isMobile }) => ($isMobile ? '100%' : 'max-content')};
  border-radius: 8px;
  box-shadow: ${({ $isMobile }) =>
    $isMobile
      ? 'none'
      : 'rgba(0, 0, 0, 0.04) 0px 24px 32px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 0px 10px;'};
  margin-top: 4px;
  z-index: 10;
  max-width: 600px;
  flex-direction: column;
  gap: ${({ $isMobile }) => ($isMobile ? '8px' : '0')};
  background: ${({ $isMobile }) => ($isMobile ? 'inherit' : 'white')};
  @media (min-width: 1200px) {
    max-width: ${({ $roomColumns }) =>
      $roomColumns === 1 ? '350px' : $roomColumns === 2 ? '600px' : '900px'};
  }
`;

export const PassengerSelector = styled.div`
  position: relative;
`;

export const RoomsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const RoomContainer = styled.div<{
  $onlyOne: boolean;
  $isMobile: boolean;
}>`
  padding: 16px 16px 20px;
  width: ${({ $onlyOne, $isMobile }) =>
    $isMobile ? '100%' : $onlyOne ? '300px' : '350px'};
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  border-radius: ${({ $isMobile }) => ($isMobile ? '10px ' : '8px 8px 0 0')};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const RowText = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AddRoom = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86px;
`;

export const Counter = styled.span`
  text-align: center;
  line-height: 24px;
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  color: rgb(60, 60, 60);
`;

export const Title = styled.span`
  line-height: 20px;
  color: rgb(60, 60, 60);
  font-weight: 700;
  font-size: 16px;
`;

export const Button = styled(ButtonBase)`
  display: block;
  border: none;
  cursor: pointer;
  padding: 0px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background: transparent;
  transition: all 0.2s ease 0s;
`;

export const RoomButton = styled.button<{ $color: string }>`
  margin-right: 0px;
  margin-left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: rgb(60, 60, 60);

  & :hover {
    color: ${({ $color }) => $color};
  }

  padding: 2px;
  margin: 0px;
  background: transparent;
  border-radius: 50%;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.06) 0px 0px 2px,
    rgba(0, 0, 0, 0.04) 0px 0px 1px;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          color: rgb(186, 186, 192);
          background: rgb(245, 245, 245);
          box-shadow: none;
          cursor: default;
          & :hover {
            color: inherit;
          }
        `
      : css``}
`;

export const RemoveButton = styled.button<{ $color: string }>`
  margin-right: 0px;
  margin-left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: #fff;
  background-color: rgb(60, 60, 60);

  & :hover {
    background-color: ${({ $color }) => $color};
  }

  padding: 2px;
  margin: 0px;
  background: transparent;
  border-radius: 50%;
  box-shadow: unset;
`;

export const AddChildButton = styled(ButtonBase)`
  color: currentColor;
  padding: 0px;
  border: 0;
  box-sizing: content-box;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 0;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f5f5f5;
  border-radius: 8px;

  &.${buttonBaseClasses.disabled} {
    background-color: rgb(241, 241, 241);

    & span {
      color: #babac0;
    }
  }

  & span {
    display: block;
    color: rgb(130, 130, 137);
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 16px;
    font-weight: 400;
  }

  & #arrow-down {
  }
`;

export const ChildAgesList = styled.ul`
  position: absolute;
  z-index: 5;
  top: 0px;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 24px 32px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 0px 10px;
  overflow: auto;
  min-height: 52px;
  max-height: 340px;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  scrollbar-width: thin;
  scrollbar-color: rgb(130, 130, 137) rgb(255, 255, 255);
`;

export const ChildAgesListItem = styled.li`
  display: block;
  padding: 10px 16px;
  line-height: 20px;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgb(60, 60, 60);
  cursor: pointer;
  transition: color 0.2s ease 0s;

  & :hover {
    color: rgb(130, 130, 137);
  }
`;

export const ChildAgesListItemDisabled = styled(ChildAgesListItem)`
  cursor: default;
  color: #babac0;
`;

export const AddChild = styled.div`
  position: relative;
`;

export const TooMuchInfantsDisclaimer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  padding: 12px 16px;
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: initial;
  z-index: 1;
  background-color: rgb(255, 233, 235);
  color: rgb(221, 0, 22);
`;

export const MobileRoomsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 8px;
`;

export const AddRoomMobile = styled.div`
  max-width: 100%;
  background: rgb(255, 255, 255);
  padding: 10px 0px;
  border-radius: 8px;
  margin-bottom: 0px;
  justify-content: center;
  text-align: center;
  display: flex;
`;

export const AddRoomMobileButton = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  gap: 5px;
`;
