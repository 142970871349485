import {
  ProposalsResponseAdvancedDto,
  TransportResponseAdvanceUpdated,
} from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import { ResultsSearchParamsData } from '../types/MappingTypes';
import { SearchType } from '../types/SearchTypes';
import { calculateTripDuration } from './calculateTripDuration';
import { findAirportInfoHDP } from './findAirportInfoHDP';
import { findAirportInfoTransport } from './findAirportInfoTransport';

export function getSearch({
  bookingType,
  paramsData,
  hdpProposals,
  fProposals,
}: {
  bookingType: string;
  paramsData: ResultsSearchParamsData;
  hdpProposals: ProposalsResponseAdvancedDto;
  fProposals: TransportResponseAdvanceUpdated;
}): SearchType {
  const {
    numberOfTravellers,
    outboundDate,
    returnDate,
    departure,
    departureName,
    departureType,
    destinationId,
    destinationName,
    destinationType,
    searchId,
  } = paramsData;

  const departureDateDayjs = dayjs(outboundDate, 'YYYYMMDD');
  const returnDateDayjs = !!returnDate
    ? dayjs(returnDate, 'YYYYMMDD')
    : undefined;

  const numberOfGuests =
    numberOfTravellers.adultNumber +
    numberOfTravellers.childrenNumber +
    numberOfTravellers.infantNumber;

  const departureWeekDay = departureDateDayjs?.format('ddd').toLowerCase();
  const returnWeekDay = returnDateDayjs?.format('ddd').toLowerCase();

  const tripDuration =
    !!departureDateDayjs && !!returnDateDayjs
      ? calculateTripDuration(
          departureDateDayjs.format(),
          returnDateDayjs.format()
        )
      : 1;
  const advancedBookingMilliseconds = departureDateDayjs.diff(dayjs());
  const advancedBooking = Math.floor(
    advancedBookingMilliseconds / (1000 * 60 * 60 * 24)
  );

  const isFlight = bookingType === 'flight';

  const firstTransportHDP = !isFlight
    ? hdpProposals?.search?.transports?.[0]
    : undefined;
  const firstTransportF = isFlight ? fProposals?.proposals[0] : undefined;
  const staticDataHDP = !isFlight ? hdpProposals?.staticData : undefined;
  const staticDataF = isFlight ? fProposals?.staticData : undefined;

  const origin = !isFlight
    ? findAirportInfoHDP(
        staticDataHDP,
        firstTransportHDP?.outbound?.departureId
      )
    : findAirportInfoTransport(
        staticDataF,
        firstTransportF.transport.ways[0].departureId
      );

  const destination = !isFlight
    ? findAirportInfoHDP(staticDataHDP, firstTransportHDP?.outbound?.arrivalId)
    : findAirportInfoTransport(
        staticDataF,
        firstTransportF.transport.ways[0].arrivalId
      );

  const routeType =
    origin?.country?.continentId && destination?.country?.continentId
      ? origin?.airport.countryId === destination?.airport.countryId
        ? 'domestic'
        : origin?.country.continentId === destination?.country.continentId
          ? 'continental'
          : 'intercontinental'
      : undefined;

  const childrenAge =
    numberOfTravellers.allChildrenAges?.filter((e) => e >= 2).length > 0
      ? numberOfTravellers.allChildrenAges?.filter((e) => e >= 2).join(',')
      : undefined;
  const infantsAge =
    numberOfTravellers.allChildrenAges?.filter((e) => e < 2).length > 0
      ? numberOfTravellers.allChildrenAges?.filter((e) => e < 2).join(',')
      : undefined;

  return {
    adults: numberOfTravellers.adultNumber,
    children: numberOfTravellers.childrenNumber,
    infants: numberOfTravellers.infantNumber,
    originCode: departure?.toUpperCase() || undefined,
    originName: departureName || undefined,
    originType: departureType,
    destinationCode: destinationId?.toUpperCase() || undefined,
    destinationName: destinationName || undefined,
    destinationType: destinationType,
    routeType: routeType,
    duration: Number(tripDuration),
    numberOfGuests: numberOfGuests,
    quantity: !isFlight ? numberOfTravellers.numberOfRooms : undefined,
    searchId,
    advancedBooking: advancedBooking,
    departureWeekDay: departureWeekDay,
    originContinentCode: origin.continent?.id || undefined,
    originContinentName: origin.continent?.label || undefined,
    originCountryName: origin.country?.label || undefined,
    returnWeekDay: returnWeekDay,
    childrenAge,
    infantsAge,

    destinationCountryCode:
      destination.country?.id ||
      destination.airport?.countryId ||
      hdpProposals?.staticData?.countries?.[0].id ||
      undefined,
    destinationCountryName:
      destination.country?.label ||
      hdpProposals?.staticData?.countries?.[0].label ||
      undefined,
    destinationContinentCode:
      destination.continent?.id ||
      hdpProposals?.staticData?.continents?.[0].id ||
      undefined,
    destinationContinentName:
      destination.continent?.label ||
      hdpProposals?.staticData?.continents?.[0].label ||
      undefined,

    departureDate: departureDateDayjs?.format('YYYYMMDD'),
    returnDate: returnDateDayjs?.format('YYYYMMDD'),
    roundTrip: firstTransportHDP?.inbound ? true : false,
  };
}
